import React, { useEffect, useReducer } from "react";
import { Typography } from "@mui/material";

import {
  selectSelectedCase,
  fetchCaseDetailsById,
  selectProfileState,
  deleteDocumentbyId,
  setSelectedCaseActivites,
  setSelectedDocument,
  selectSelectedCaseStatus,
  selectUploadComplete,
  selectSelectedCaseSummary,
  selectSelectedCasePrivicyLevel,
  setCaseEditsToSave,
} from "@/redux/casesSlice";
import { useAppSelector, useAppDispatch } from "@/hooks";
import FileUploader from "../FileUploader";
import CasePrivacyRestrictions from "../CasePrivacyRestrictions";
import EditableSummary from "../EditableSummary";
import * as Styled from "./CaseProfileWrapper.styles";
import CaseDetails from "./CaseDetails";
import CaseRequirementsStatus from "./CaseRequirementsStatus";
import DocumentList from "./DocumentList";
import FileDeleteModal from "./FileDeleteModal";
import Activities from "@/components/Activities";
import { IDocument } from "@/redux/store.types";
import { RenderIf } from "@adl/smart-ui-library";
import { POLLING_INTERVAL } from "../../constants";
import {
  reducer,
  SET_PRIVACY_LEVEL,
  SET_SUMMARY,
} from "./CaseProfileWrapper.reducer";

const CaseProfileWrapper = () => {
  const selectedCase = useAppSelector(selectSelectedCase);
  const caseSummary = useAppSelector(selectSelectedCaseSummary);
  const casePrivacylevel = useAppSelector(selectSelectedCasePrivicyLevel);
  const appDispatch = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, {
    summary: caseSummary,
    privacyLevel: casePrivacylevel,
  });

  useEffect(() => {
    dispatch({ type: SET_SUMMARY, payload: caseSummary });
    dispatch({ type: SET_PRIVACY_LEVEL, payload: casePrivacylevel });
  }, [caseSummary, casePrivacylevel]);

  useEffect(() => {
    const hasChanged =
      state.summary !== caseSummary || state.privacyLevel !== casePrivacylevel;

    if (hasChanged) {
      console.log("CHANGE");
      appDispatch(setCaseEditsToSave(state));
    } else {
      console.log("NO CHANGE");
      appDispatch(setCaseEditsToSave(null));
    }
  }, [state]);

  const { ShortName, DeliveryEngagementId } = selectedCase;
  return (
    <>
      <Styled.CaseDetailsContent id="case-details-content">
        <Styled.CaseDetailsContentLeft id="case-details-content-left">
          <Typography variant="h5">{ShortName}</Typography>
          <CasePrivacyRestrictions
            privacyLevel={state.privacyLevel}
            onUpdate={(level) =>
              dispatch({ type: SET_PRIVACY_LEVEL, payload: level })
            }
          />
          <EditableSummary
            onUpdate={(text) => {
              if (text === state.summary) return;
              dispatch({ type: SET_SUMMARY, payload: text });
            }}
            text={state.summary}
          />
          <CaseDetails />
          <CaseProfile />
        </Styled.CaseDetailsContentLeft>
        <Styled.CaseDetailsContentRight id="case-details-content-right">
          <FileUploader
            id={DeliveryEngagementId}
            email={"kamba.marina@adlittle.com"}
            uploadComplete={() => null}
          />
          <Activities />
        </Styled.CaseDetailsContentRight>
      </Styled.CaseDetailsContent>
    </>
  );
};

const CaseProfile = () => {
  const appDispatch = useAppDispatch();
  const selectedCase = useAppSelector(selectSelectedCase);
  const caseDocuments = useAppSelector(selectProfileState);
  const statuses = useAppSelector(selectSelectedCaseStatus);
  const isFileUploadComplete = useAppSelector(selectUploadComplete);
  const [isRefresh, setIsRefresh] = React.useState<boolean>(false);
  const { DeliveryEngagementId } = selectedCase;

  const [skip, setSkip] = React.useState(0);
  const [toDelete, setToDelete] = React.useState<number | string | null>(null);

  const refreshData = async (refresh?: boolean) => {
    setIsRefresh(Boolean(refresh));
    appDispatch(
      fetchCaseDetailsById({
        deliveryEngagementId: DeliveryEngagementId,
        top: 10,
        skip: skip,
        hideLoading: refresh,
      })
    );
  };

  const onUpdatePagination = (skip: number) => {
    setSkip(skip);
  };

  const onSelectDocument = (data: IDocument) => {
    appDispatch(setSelectedCaseActivites(null));
    appDispatch(setSelectedDocument(data));
  };

  const onDeleteFile = async (documentId) => {
    try {
      await appDispatch(
        deleteDocumentbyId({
          deliveryEngagementId: DeliveryEngagementId,
          documentId,
        })
      );
      setToDelete(null);
      refreshData();
    } catch (error) {
      console.error("Failed to delete document:", error);
    }
  };

  useEffect(() => {
    refreshData(false);

    const polling = setInterval(() => {
      refreshData(true);
    }, POLLING_INTERVAL);
    return () => {
      clearInterval(polling);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  useEffect(() => {
    isFileUploadComplete && refreshData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileUploadComplete]);

  return (
    <>
      <CaseRequirementsStatus statuses={statuses} isRefresh={isRefresh} />
      <DocumentList
        {...caseDocuments}
        onUpdate={onUpdatePagination}
        onDeleteFile={(id) => setToDelete(id)}
        onSelectDocument={onSelectDocument}
        isRefresh={isRefresh}
      />

      <RenderIf isTrue={toDelete !== null}>
        <FileDeleteModal
          onCancel={() => setToDelete(null)}
          onClose={() => onDeleteFile(toDelete)}
        >
          Are you sure you want to delete?
        </FileDeleteModal>
      </RenderIf>
    </>
  );
};

export default CaseProfileWrapper;
