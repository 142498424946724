import {
  Typography,
  Box,
  useTheme,
  Button,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  Alert,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  selectSelectedCases,
  selectSelectedCaseDetails,
} from "@/redux/casesSlice";
import { Modal, RenderIf, Show } from "@adl/smart-ui-library";
import EditableSummary from "./EditableSummary";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled, alpha } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { formatDate } from "../../utils";
import axiosInstance from "../../axiosConfig";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReactMarkdown from "react-markdown";

const CasePill = ({
  title,
  value,
  colour,
}: {
  title: string;
  value: string | number;
  colour?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: colour
          ? `1px solid ${colour}`
          : `1px solid ${theme.palette.grey[300]}`,
        padding: "4px 10px 3px 10px",
        borderRadius: "5px",
        width: "fit-content",
        display: "flex",
        gap: "5px",
      }}
      onClick={() => null}
    >
      <Typography
        variant="body1"
        sx={{
          color: colour || theme.palette.grey[600],
        }}
      >
        {title}:
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: colour || theme.palette.grey[600],
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    background: "none !important",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {},
  },
}));

const savedSummary =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sapien orci, lacinia sed lorem eu, condimentum congue lorem. Phasellus dictum imperdiet lorem, vel gravida neque placerat a. Praesent ornare ligula sed turpis efficitur, pellentesque ullamcorper sapien pretium. Etiam eget erat rutrum, vulputate sapien eu, imperdiet ex. Maecenas mollis ultrices tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas pellentesque ligula sed leo varius, nec egestas est mattis. Mauris vel sapien consequat, consequat dolor id, tincidunt magna. Fusce dapibus gravida gravida. Etiam velit dui, pulvinar ac risus aliquam, venenatis congue dolor. Donec eu ex ac ex lacinia pulvinar. Pellentesque sollicitudin nulla a lacinia consequat. Suspendisse semper neque eu laoreet hendrerit. Cras mattis vel est et pellentesque.";

const TaskCard = ({ task, done, handleVerify, privacyLevels, fileCategories }: Props) => {
  const appDispatch = useAppDispatch();
  const data = useAppSelector(selectSelectedCaseDetails);
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [fileCategory, setFileCategory] = React.useState(
    task?.document?.categoryId
  );
  const [privacyLevel, setPrivacyLevel] = React.useState(
    task?.document?.privacyLevels
  );
  const [summary, setSummary] = React.useState(task?.document?.summary);
  const [editSummary, setEditSummary] = React.useState(false);

  function getStyles() {
    return {
      textAlign: "left",
      borderRadius: "20px",
      border: "1px solid #ccc",
      fontSize: "14px",
    };
  }

  const handleFileCategory = (event) => {
    setFileCategory(event.target.value);
  };

  const handlePrivacy = (event, value) => {
    const replaceObj = {
      privacyTypeId: value,
      privacyTypeValue: event.target.checked,
    };

    const index = privacyLevel.findIndex(
      (obj) => obj.privacyTypeId === replaceObj.privacyTypeId
    );
    if (index !== -1) {
      privacyLevel.splice(index, 1, replaceObj);
    }
    setPrivacyLevel(privacyLevel);
  };
  const handleRegenerate = async () => {
    try {
      const result: any = await axiosInstance.get(
        `tasks/${task.taskId}/document/summary`
      );
      console.log(result);
      setSummary(result.data.summary);
    } catch (error) {
      setSummary(savedSummary);
      console.error("Error fetching notifications:", error);
    }
  };

  const onVerify = () => {
    console.log(privacyLevel);

    handleVerify({
      taskId: task.taskId,
      taskType: "DocumentVerification",
      document: {
        summary: summary,
        categoryId: fileCategory,
        privacyLevels: privacyLevel,
      },
    });
  };

  const getPrivacyLevel = (id, value) => {
    switch (id) {
      case 1:
        return ` Financial data ${value ? "protected" : "unprotected"}`;
      case 2:
        return ` Market data ${value ? "protected" : "unprotected"}`;
      case 3:
        return ` Company data ${value ? "protected" : "unprotected"}`;
      case 4:
        return ` Competitor data ${value ? "protected" : "unprotected"}`;
      case "failed":
      default:
        return "Not available";
    }
  };
  useEffect(() => {
    setSummary(task?.document?.summary);
  }, [task]);

  return (
    <SimpleTreeView
      sx={{
        background: "transparent",
      }}
    >
      <CustomTreeItem
        itemId="grid"
        sx={{
          fontSize: "16px",
          padding: "10px 0",
          marginBottom: "20px",
          background: "none !important",
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: "10px",
          // boxShadow: theme.shadows[1],
        }}
        label={
          <div style={{ position: "relative" }}>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary[700],
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {task?.document?.fileNameOriginal}{" "}
              {task?.verified ? "" : "needs to be validated"}
            </Typography>
            <div style={{ position: "absolute", right: "0", top: "0" }}>
              {task?.verified ? (
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              ) : (
                <PanoramaFishEyeIcon style={{ color: "red" }} />
              )}
            </div>
          </div>
        }
      >
        <Box
          sx={{
            width: "100%",
            padding: "10px",
            margin: "0",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <CasePill title="Created" value={formatDate(task?.createdAt)} />
            <CasePill title="Created by" value={task?.createdBy} />
            {task?.verified && (
              <>
                {task?.verifiedAt && (
                  <CasePill
                    title="Validated"
                    value={formatDate(task?.verifiedAt)}
                  />
                )}
                <CasePill title="Validated by" value={task?.verifiedBy} />
                <CasePill
                  title="Category"
                  value={task?.document.categoryName}
                />

                <CasePill
                  title="Privacy"
                  value={task?.document?.privacyLevels
                    .map((privacyLevel) =>
                      getPrivacyLevel(
                        privacyLevel.privacyTypeId,
                        privacyLevel.privacyTypeValue
                      )
                    )
                    .toString()}
                />
              </>
            )}
          </Box>

          <Box>
            <Box sx={{ display: "flex", alignItems: "center", height: "50px" }}>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.grey[800],
                }}
              >
                Summary
              </Typography>
              <RenderIf isTrue={!task?.verified}>
                <IconButton onClick={() => setEditSummary(true)}>
                  <EditOutlinedIcon />
                </IconButton>
              </RenderIf>

              <RenderIf isTrue={false}>
                <IconButton onClick={handleRegenerate}>
                  <Tooltip title="Regenerate summary" arrow>
                    <RotateLeftIcon />
                  </Tooltip>
                </IconButton>
              </RenderIf>
            </Box>
            <Show>
              <Show.When isTrue={editSummary}>
                <TextField
                  variant="outlined"
                  placeholder="Add summary"
                  onKeyDown={(e) => e.stopPropagation()}
                  sx={{
                    width: "100%",

                    "& .MuiOutlinedInput-root": {
                      padding: "20px",
                      height: "auto",
                      alignItems: "flex-start",
                    },
                    "& .MuiOutlinedInput-inputMultiline": {
                      padding: "20px",
                    },
                  }}
                  onChange={(e) => setSummary(e.target.value)}
                  value={summary}
                  multiline
                />
              </Show.When>
              <Show.Else>
                <Show>
                  <Show.When isTrue={Boolean(summary)}>
                    <Box sx={{ minHeight: "10px" }}>
                      <ReactMarkdown data-test="document-summary">
                        {summary}
                      </ReactMarkdown>
                    </Box>
                  </Show.When>
                  <Show.Else>
                    <Alert severity="info">
                      Please add a document summary.
                    </Alert>
                  </Show.Else>
                </Show>
              </Show.Else>
            </Show>
          </Box>
          {!task?.verified && (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  margin: "0",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <FormControl style={{ width: "40%", margin: "20px 0" }}>
                  <InputLabel style={{ background: "#fff" }} id="fileCategory">
                    Document type
                  </InputLabel>
                  <Select
                    labelId="fileCategory"
                    id="fileCategory"
                    value={fileCategory}
                    disabled={task?.verified}
                    onChange={handleFileCategory}
                    style={getStyles()}
                  >
                    {fileCategories.map((category) => (
                      <MenuItem
                        key={category.value}
                        value={category.documentCategoryId}
                      >
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormGroup>
                  {privacyLevels &&
                    privacyLevels.map((level) => (
                      <FormControlLabel
                        id={level.privacyTypeId}
                        onChange={(e) => handlePrivacy(e, level.privacyTypeId)}
                        control={
                          <Switch
                            defaultChecked={
                              task?.document?.privacyLevels.find(
                                (i) => i.privacyTypeId === level.privacyTypeId
                              )?.privacyTypeValue
                            }
                          />
                        }
                        label={`Protect ${level.name}`}
                      />
                    ))}
                </FormGroup>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              marginTop: "10px",
              marginBottom: "50px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          ></Box>
          {!task?.verified && (
            <Box
              sx={{
                position: "relative",
                right: "20px",
                bottom: "10px",
                display: "flex",
                gap: "10px",
                float: "right",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              ></Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  disabled={!summary}
                  onClick={onVerify}
                  sx={{ width: "100%" }}
                >
                  Commit
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </CustomTreeItem>
    </SimpleTreeView>
  );
};

export default TaskCard;
