import React, { useEffect } from "react";
import { Show } from "@adl/smart-ui-library";
import {
  Alert,
  Box,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReactMarkdown from "react-markdown";
import { motion } from "framer-motion";
import { ANNIMATION_SPEED } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

type Props = {
  text: string;
  onUpdate: (text: string) => void;
};

const EditableSummary = ({ text, onUpdate }: Props) => {
  const [editSummary, setEditSummary] = React.useState(false);
  const [summary, setSummary] = React.useState(text);

  const theme = useTheme();

  useEffect(() => {
    setSummary(text);
  }, [text]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
      >
        <Typography
          variant="h6"
          sx={{ marginBottom: "15px", paddingTop: "3px" }}
        >
          Summary
        </Typography>
        <Box>
          <Show>
            <Show.When isTrue={editSummary}>
              <IconButton
                size="small"
                onClick={() => {
                  setSummary(text);
                  setEditSummary(false);
                }}
              >
                <CloseIcon
                  sx={{ fontSize: "18px", color: theme.palette.error.main }}
                />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  setEditSummary(false);
                  onUpdate(summary);
                }}
              >
                <DoneIcon
                  sx={{ fontSize: "18px", color: theme.palette.success.main }}
                />
              </IconButton>
            </Show.When>
            <Show.Else>
              <IconButton size="small" onClick={() => setEditSummary(true)}>
                <EditOutlinedIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </Show.Else>
          </Show>
        </Box>
      </Box>
      <Show>
        <Show.When isTrue={editSummary}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: ANNIMATION_SPEED }}
          >
            <TextField
              variant="outlined"
              autoFocus={false}
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  padding: "20px",
                  height: "auto",
                  alignItems: "flex-start",
                },
                "& .MuiOutlinedInput-inputMultiline": {
                  padding: "20px",
                },
              }}
              onChange={(e) => setSummary(e.target.value)}
              value={summary}
              multiline
            />
          </motion.div>
        </Show.When>
        <Show.Else>
          {summary && summary.length > 0 ? (
            <ReactMarkdown>{summary}</ReactMarkdown>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: ANNIMATION_SPEED }}
            >
              <Alert severity="info">Please add case summary.</Alert>
            </motion.div>
          )}
        </Show.Else>
      </Show>
    </Box>
  );
};

export default EditableSummary;
