import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Modal } from "@adl/smart-ui-library";

type Props = {
  onClose: () => void;
  onCancel: () => void;
  children: React.ReactNode;
};

const FileDeleteModal = ({ onClose, children, onCancel }: Props) => {
  return (
    <Modal open={true} onClose={() => null} name="delete-file-confirmation">
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" disableElevation onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disableElevation onClick={onClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default FileDeleteModal;
