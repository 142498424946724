import React, { useEffect } from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { Box } from "@mui/material";
import { LoadingSpinner, RenderIf } from "@adl/smart-ui-library";
import { setUpdatedTasks } from "@/redux/tasksSlice";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  selectUploadComplete,
  setUploadComplete,
} from "../../redux/casesSlice";
import FileMetaModal from "./FileMetaModal";
import FileUploadStatusModal from "./FileUploadStatusModal";
import {
  fetchCategories,
  fetchPrivacyLevels,
  getUploadUrl,
  uploadFile,
} from "./FileUploader.services";

type ErrorState = React.ReactElement | null;

type Props = {
  id: string;
  email: string;
  uploadComplete: () => void;
};

const FileUploader = ({ id, uploadComplete }: Props) => {
  const [files, setFiles] = React.useState<any[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ErrorState>(null);
  const [currentFileIndex, setCurrentFileIndex] = React.useState<number>(0);
  const filePondRef = React.useRef<FilePond | null>(null);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const appDispatch = useAppDispatch();
  const [categories, setCategories] = React.useState([]);
  const [privacyLevels, setPrivacyLevels] = React.useState([]);
  const isComplete = useAppSelector(selectUploadComplete);
  const isError = error !== null;
  const allowedExtensions = ["docx", "pptx", "pdf", "xlsx"];

  useEffect(() => {
    if (error !== null) {
      setIsUploading(false);
    }
  }, [error]);

  useEffect(() => {
    if (files.length > 0 && currentFileIndex < files.length) {
      setModalOpen(true);
      loadMetaData();
    }
  }, [files, currentFileIndex]);

  const loadMetaData = async () => {
    try {
      const [categoriesData, privacyLevelsData] = await Promise.all([
        fetchCategories(),
        fetchPrivacyLevels(),
      ]);
      setCategories(categoriesData);
      setPrivacyLevels(privacyLevelsData);
    } catch (error) {
      console.error("Error loading metadata:", error);
    }
  };

  const handleFileAdd = (error, file: any) => {
    if (!error) {
      setFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const handleUpdateFiles = (fileItems) => {
    setFiles(fileItems);
  };

  const onCloseMetaModal = async (fileMetadataForFile) => {
    setIsUploading(true);
    const { fileCategory, privacyTypeId, privacyTypeValue, privacyLevel } =
      fileMetadataForFile;
    const file = files[currentFileIndex].file;
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (
      (fileCategory === 1 || fileCategory === 2) &&
      !allowedExtensions.includes(fileExtension)
    ) {
      setError(
        <>
          Invalid file type. You can only upload {allowedExtensions.join(", ")}{" "}
          files for Proposal and Qual.
        </>
      );
      setIsUploading(false);
      return;
    }
    const privacyLevelsString = [""];
    privacyLevel.map((level) =>
      privacyLevelsString.push(
        `privacyTypeId=${level.privacyTypeId}&privacyTypeValue=${level.privacyTypeValue}`
      )
    );

    try {
      const result: any = await getUploadUrl(
        id,
        file.name,
        fileCategory,
        privacyLevelsString
      );

      if (result.statusText === "OK") {
        //setUrl(result.data.results[0]);
        const file = files[currentFileIndex].file;
        if (filePondRef.current && file) {
          handleUpload(file, result.data.results[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching file upload URL:", error);
      setError(<>Ooops something went wrong. Please try again.</>);
    }

    // Move to the next file
    //setCurrentFileIndex((prevIndex) => prevIndex + 1);
    if (currentFileIndex + 1 >= files.length) {
      setModalOpen(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setFiles([]);
    if (filePondRef.current) {
      filePondRef.current.removeFiles();
    }
  };

  const handleUpload = async (file, url) => {
    console.log("Uploading file", file);
    try {
      const response: any = await uploadFile(file, url);

      if (response.status !== 201) {
        throw new Error("Upload failed");
      }

      console.log("File uploaded successfully");
      const previousFiles = [...files];
      previousFiles.shift();
      setFiles(previousFiles);
      appDispatch(setUpdatedTasks(true));
      appDispatch(setUploadComplete(true));
      setIsUploading(false);
      uploadComplete();
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(<>Ooops something went wrong. Please try again</>);
    }
  };
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <RenderIf isTrue={isUploading}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 299,
              borderRadius: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <LoadingSpinner />
          </Box>
        </RenderIf>
        <FilePond
          ref={filePondRef}
          files={files}
          onupdatefiles={handleUpdateFiles}
          onaddfile={handleFileAdd}
          allowProcess={false}
          allowMultiple={true}
          maxFiles={10}
          instantUpload={false}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
      </Box>
      {isComplete && <p>Upload Complete!</p>}
      <RenderIf isTrue={isComplete}>
        <FileUploadStatusModal
          onClose={() => {
            appDispatch(setUploadComplete(false));
          }}
        >
          File uploaded successfully.
        </FileUploadStatusModal>
      </RenderIf>
      <RenderIf isTrue={isError}>
        <FileUploadStatusModal
          onClose={() => {
            setError(null);
            setFiles([]);

            setModalOpen(false);
          }}
        >
          <span style={{ color: "red" }}>{error}</span>
        </FileUploadStatusModal>
      </RenderIf>
      <RenderIf isTrue={modalOpen}>
        <FileMetaModal
          onClose={(fileMetadataForFile) =>
            onCloseMetaModal(fileMetadataForFile)
          }
          onCancel={handleCancel}
          data={files[currentFileIndex]}
          index={currentFileIndex}
          total={files.length}
          privacyLevels={privacyLevels}
          categories={categories}
        />
      </RenderIf>
    </>
  );
};

export default FileUploader;
