import { Chip, useTheme } from "@mui/material";
import React from "react";

type Props = {
  status: string;
};

const StatusChip = ({ status }: Props) => {
  const theme = useTheme();
  const text = () => {
    switch (status) {
      case "waitingforupload":
        return "Waiting for upload";
      case "processing":
        return "Processing";
      case "completed":
        return "Completed";
      case "failed":
        return "Failed";
      case "pendingverification":
        return "Pending Validation";
      default:
        return "Validated";
    }
  };

  const colour = () => {
    switch (status) {
      case "waitingforupload":
        return theme.palette.error.main;
      case "processing":
        return theme.palette.warning.main;
      case "completed":
        return theme.palette.success.main;
      case "failed":
        return theme.palette.error.main;
      case "pendingverification":
        return theme.palette.warning.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <Chip
      variant="outlined"
      label={`Status: ${text()}`}
      sx={{ color: colour(), borderColor: colour() }}
    />
  );
};

export default StatusChip;
