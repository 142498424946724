import React from "react";
import { Chip, Typography } from "@mui/material";
import * as Styled from "./ActivitiesRendererItem.styles";
import { camelCaseToWords } from "../../../utils";

type Props = {
  activity: string;
  name: string;
  date: string;
  user: string;
};

const ActivitiesRendererItem = ({ activity, name, date, user }: Props) => {
  return (
    <Styled.ActivitiesRenderItemWrapper>
      <Styled.ActivitiesRenderItemHeader>
        <Chip label={camelCaseToWords(activity.split(".")[1])} />
      </Styled.ActivitiesRenderItemHeader>
      <Typography variant="h3">{name}</Typography>
      <Styled.ActivitiesRenderItemBody>
        <Typography variant="body1">{date}</Typography>
        <Typography variant="body1">{user}</Typography>
      </Styled.ActivitiesRenderItemBody>
    </Styled.ActivitiesRenderItemWrapper>
  );
};

export default ActivitiesRendererItem;
