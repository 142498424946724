import React, { useEffect, useReducer } from "react";
import { useAppSelector, useAppDispatch } from "@/hooks";
import * as Styled from "./DocumentProfileWrapper.styles";
import {
  selectSelectedDocument,
  selectDocumentCatagories,
  setCaseEditsToSave,
} from "@/redux/casesSlice";
import { formatDate } from "../../utils";
import { Box, Chip, Typography } from "@mui/material";
import StatusChip from "@/components/StatusChip";
import EditableCategories from "@/components/EditableCategories";
import PrivacyLevels from "@/components/PrivacyLevels";
import EditableSummary from "../EditableSummary";
import {
  reducer,
  SET_CATEGORY,
  SET_CONFIFENTIALITY_LEVEL,
  SET_SUMMARY,
} from "./DocumentProfileWrapper.reducer";
import _ from "lodash";
const DocumentProfileWrapper = () => {
  const document = useAppSelector(selectSelectedDocument);
  const categories = useAppSelector(selectDocumentCatagories);
  const appDispatch = useAppDispatch();
  const [state, dispatch] = useReducer(reducer, document);

  const { fileNameOriginal, categoryId, documentPrivacyLevels, summary } =
    state;

  useEffect(() => {
    const hasChanged = !_.isEqual(document, state);
    if (hasChanged) {
      appDispatch(setCaseEditsToSave(state));
    } else {
      appDispatch(setCaseEditsToSave(null));
    }
  }, [state, document, appDispatch]);

  return (
    <Styled.DocumentProfileWrapper id="document-profile-content">
      <Styled.DocumentProfileWrapperLeft id="document-profile-left">
        <Typography variant="h5">{fileNameOriginal}</Typography>
        <Typography variant="h6">Overview</Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Chip
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            label={`Created: ${formatDate(document?.createdAt)}`}
          />

          <Chip
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            label={`Modified: ${formatDate(document?.updatedAt)}`}
          />

          <Chip
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            label={`Category: ${
              categories.find(
                (cat) => cat.documentCategoryId === document?.categoryId
              ).name
            }`}
          />

          <StatusChip status={document.fileStatus} />
        </Box>
        <Typography variant="h6">File Category</Typography>
        <EditableCategories
          value={categoryId}
          onUpdate={(value) => {
            dispatch({ type: SET_CATEGORY, payload: value });
          }}
        />
        <Typography variant="h6">Privacy Level</Typography>
        <PrivacyLevels
          values={documentPrivacyLevels}
          onUpdate={(value) => {
            dispatch({ type: SET_CONFIFENTIALITY_LEVEL, payload: value });
          }}
        />
        <EditableSummary
          text={summary}
          onUpdate={(text) => {
            if (text === summary) return;
            dispatch({ type: SET_SUMMARY, payload: text });
          }}
        />
      </Styled.DocumentProfileWrapperLeft>
    </Styled.DocumentProfileWrapper>
  );
};

export default DocumentProfileWrapper;
