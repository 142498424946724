import { Box, styled } from "@mui/system";

export const DocumentProfileWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "0px 120px 0px 120px",
  gap: "80px",
  width: "100%",
  maxWidth: theme.breakpoints.values.xl,
  [theme.breakpoints.down("xl")]: {
    padding: "0px 120px 0px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0px 60px 0px 60px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0px 40px 0px 40px",
  },

  "& .filepond--credits": {
    display: "none",
  },
}));

export const DocumentProfileWrapperLeft = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  flexDirection: "column",

  gap: "40px",

  [theme.breakpoints.down("xl")]: {
    width: "60%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "900px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "center",
  },
}));

export const DocumentProfileWrapperRight = styled(Box)(({ theme }) => ({
  width: "40%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
