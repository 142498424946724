import { ICaseEditsState } from "@/redux/store.types";

export const SET_SUMMARY = "SET_SUMMARY";
export const SET_PRIVACY_LEVEL = "SET_PRIVACY_LEVEL";

export const reducer = (state: ICaseEditsState, action) => {
  switch (action.type) {
    case SET_SUMMARY: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case SET_PRIVACY_LEVEL: {
      return {
        ...state,
        privacyLevel: action.payload,
      };
    }
  }

  return state;
};
