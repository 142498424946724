import { IFilterMenu } from "@adl/smart-ui-library";

const industries: IFilterMenu[] = [
  {
    type: "industries",
    label: "Industrial Goods and Services",
    value: 1,
    param: "Industrial Goods and Services",
  },
  { type: "industries", label: "Rail", value: 2, param: "Rail" },
  {
    type: "industries",
    label: "Banking (retail and corporate)",
    value: 3,
    param: "Banking (retail and corporate)",
  },
  { type: "industries", label: "Government", value: 4, param: "Government" },
  {
    type: "industries",
    label: "Asset management (private banking, pensions, etc.)",
    value: 5,
    param: "Asset management (private banking, pensions, etc.)",
  },
  {
    type: "industries",
    label: "IT & SW companies",
    value: 6,
    param: "IT & SW companies",
  },
  { type: "industries", label: "Utilities", value: 7, param: "Utilities" },
  {
    type: "industries",
    label: "Telecom Operators & Regulators",
    value: 8,
    param: "Telecom Operators & Regulators",
  },
  { type: "industries", label: "Automotive", value: 9, param: "Automotive" },
  { type: "industries", label: "Logistics", value: 10, param: "Logistics" },
  { type: "industries", label: "Other", value: 11, param: "Other" },
  {
    type: "industries",
    label: "Food & Beverage",
    value: 12,
    param: "Food & Beverage",
  },
  {
    type: "industries",
    label: "Media & Content Players",
    value: 13,
    param: "Media & Content Players",
  },
  { type: "industries", label: "Insurance", value: 14, param: "Insurance" },
  {
    type: "industries",
    label: "Aerospace and Defense",
    value: 15,
    param: "Aerospace and Defense",
  },
  { type: "industries", label: "CRO/CDMO", value: 16, param: "CRO/CDMO" },
  {
    type: "industries",
    label: "Health Care Providers",
    value: 17,
    param: "Health Care Providers",
  },
  {
    type: "industries",
    label: "Pharmaceuticals",
    value: 18,
    param: "Pharmaceuticals",
  },
  {
    type: "industries",
    label: "Medical Technology",
    value: 19,
    param: "Medical Technology",
  },
  {
    type: "industries",
    label: "Payments and Digital Innovation",
    value: 20,
    param: "Payments and Digital Innovation",
  },
  { type: "industries", label: "Aviation", value: 21, param: "Aviation" },
  {
    type: "industries",
    label: "Private Equity",
    value: 22,
    param: "Private Equity",
  },
  {
    type: "industries",
    label: "Consumer Health",
    value: 23,
    param: "Consumer Health",
  },
  {
    type: "industries",
    label: "Commodity Chemicals",
    value: 24,
    param: "Commodity Chemicals",
  },
  { type: "industries", label: "Regional", value: 25, param: "Regional" },
  {
    type: "industries",
    label: "Infrastructure Funds",
    value: 26,
    param: "Infrastructure Funds",
  },
  {
    type: "industries",
    label: "Electronics & Infrastructure Vendor",
    value: 27,
    param: "Electronics & Infrastructure Vendor",
  },
  { type: "industries", label: "Materials", value: 28, param: "Materials" },
  {
    type: "industries",
    label: "Other Consumer",
    value: 29,
    param: "Other Consumer",
  },
  { type: "industries", label: "Oil and Gas", value: 30, param: "Oil and Gas" },
  {
    type: "industries",
    label: "Goods, Retail",
    value: 31,
    param: "Goods, Retail",
  },
  {
    type: "industries",
    label: "Diagnostics Technology",
    value: 32,
    param: "Diagnostics Technology",
  },
  {
    type: "industries",
    label: "Fixed Access",
    value: 33,
    param: "Fixed Access",
  },
  { type: "industries", label: "Specialties", value: 34, param: "Specialties" },
  { type: "industries", label: "Mobility", value: 35, param: "Mobility" },
  {
    type: "industries",
    label: "Natural Resources",
    value: 36,
    param: "Natural Resources",
  },
  {
    type: "industries",
    label: "Digital Health",
    value: 37,
    param: "Digital Health",
  },
  {
    type: "industries",
    label: "Investors & owners in Health Care Life Sciences",
    value: 38,
    param: "Investors & owners in Health Care Life Sciences",
  },
  {
    type: "industries",
    label: "Pulp, Paper and Metals",
    value: 39,
    param: "Pulp, Paper and Metals",
  },
  {
    type: "industries",
    label: "Early stage Life Sciences",
    value: 40,
    param: "Early stage Life Sciences",
  },
  {
    type: "industries",
    label: "Hospitality & Tourism",
    value: 41,
    param: "Hospitality & Tourism",
  },
  { type: "industries", label: "Postal", value: 42, param: "Postal" },
  {
    type: "industries",
    label: "Health Care Payers",
    value: 43,
    param: "Health Care Payers",
  },
  {
    type: "industries",
    label: "Agro-chemicals",
    value: 44,
    param: "Agro-chemicals",
  },
  { type: "industries", label: "Chemicals", value: 45, param: "Chemicals" },
  {
    type: "industries",
    label: "Consumer Goods",
    value: 46,
    param: "Consumer Goods",
  },
  {
    type: "industries",
    label: "Energy ＆ Utilities",
    value: 47,
    param: "Energy ＆ Utilities",
  },
  {
    type: "industries",
    label: "Engineering ＆ Manufacturing",
    value: 48,
    param: "Engineering ＆ Manufacturing",
  },
  {
    type: "industries",
    label: "Financial Services",
    value: 49,
    param: "Financial Services",
  },
  { type: "industries", label: "Healthcare", value: 50, param: "Healthcare" },
  {
    type: "industries",
    label: "Public Sector ＆ Services",
    value: 51,
    param: "Public Sector ＆ Services",
  },
  { type: "industries", label: "TIME", value: 52, param: "TIME" },
  {
    type: "industries",
    label: "Travel ＆ Transportation",
    value: 53,
    param: "Travel ＆ Transportation",
  },
  { type: "industries", label: "Solar", value: 54, param: "Solar" },
  {
    type: "industries",
    label: "Information Technology",
    value: 55,
    param: "Information Technology",
  },
  {
    type: "industries",
    label: "Products ＆ Devices",
    value: 56,
    param: "Products ＆ Devices",
  },
  {
    type: "industries",
    label: "Autonomous Driving",
    value: 57,
    param: "Autonomous Driving",
  },
  { type: "industries", label: "Mobile", value: 58, param: "Mobile" },
  {
    type: "industries",
    label: "Electrics / Electronics / Software",
    value: 59,
    param: "Electrics / Electronics / Software",
  },
  {
    type: "industries",
    label: "Insurance Industry",
    value: 60,
    param: "Insurance Industry",
  },
  { type: "industries", label: "Oil", value: 61, param: "Oil" },
  {
    type: "industries",
    label: "Commercial Vehicle",
    value: 62,
    param: "Commercial Vehicle",
  },
  {
    type: "industries",
    label: "Powertrain Alternative",
    value: 63,
    param: "Powertrain Alternative",
  },
  {
    type: "industries",
    label: "Infrastructure Equipment",
    value: 64,
    param: "Infrastructure Equipment",
  },
  { type: "industries", label: "Railways", value: 65, param: "Railways" },
  {
    type: "industries",
    label: "Local Public Transport",
    value: 66,
    param: "Local Public Transport",
  },
  { type: "industries", label: "Metal", value: 67, param: "Metal" },
  {
    type: "industries",
    label: "Component Supplier",
    value: 68,
    param: "Component Supplier",
  },
  {
    type: "industries",
    label: "Machine / System Supplier",
    value: 69,
    param: "Machine / System Supplier",
  },
  {
    type: "industries",
    label: "National Institution ＆ Administration",
    value: 70,
    param: "National Institution ＆ Administration",
  },
  {
    type: "industries",
    label: "Distribution",
    value: 71,
    param: "Distribution",
  },
  {
    type: "industries",
    label: "Transmission",
    value: 72,
    param: "Transmission",
  },
  {
    type: "industries",
    label: "Aftermarket Parts ＆ Service Provider",
    value: 73,
    param: "Aftermarket Parts ＆ Service Provider",
  },
  {
    type: "industries",
    label: "Technical Building Equipment",
    value: 74,
    param: "Technical Building Equipment",
  },
  { type: "industries", label: "Airport", value: 75, param: "Airport" },
  {
    type: "industries",
    label: "Energy Generation Products",
    value: 76,
    param: "Energy Generation Products",
  },
  {
    type: "industries",
    label: "Machinery for the Process Industry",
    value: 77,
    param: "Machinery for the Process Industry",
  },
  {
    type: "industries",
    label: "Rail ＆ Local Public Transport",
    value: 78,
    param: "Rail ＆ Local Public Transport",
  },
  {
    type: "industries",
    label: "Mobile Data Services",
    value: 79,
    param: "Mobile Data Services",
  },
  {
    type: "industries",
    label: "Mobile Voice Services",
    value: 80,
    param: "Mobile Voice Services",
  },
  {
    type: "industries",
    label: "Building Materials",
    value: 81,
    param: "Building Materials",
  },
  {
    type: "industries",
    label: "Future of Urban Mobility",
    value: 82,
    param: "Future of Urban Mobility",
  },
  {
    type: "industries",
    label: "Business Services Industry",
    value: 83,
    param: "Business Services Industry",
  },
  {
    type: "industries",
    label: "Oil ＆ Gas Regulation ＆ Legal Support",
    value: 84,
    param: "Oil ＆ Gas Regulation ＆ Legal Support",
  },
  {
    type: "industries",
    label: "Fast Moving Consumer Goods",
    value: 85,
    param: "Fast Moving Consumer Goods",
  },
  { type: "industries", label: "Food", value: 86, param: "Food" },
  {
    type: "industries",
    label: "Oil ＆ Gas Upstream",
    value: 87,
    param: "Oil ＆ Gas Upstream",
  },
  { type: "industries", label: "Cargo Rail", value: 88, param: "Cargo Rail" },
  {
    type: "industries",
    label: "Passenger Rail",
    value: 89,
    param: "Passenger Rail",
  },
  { type: "industries", label: "Education", value: 90, param: "Education" },
  {
    type: "industries",
    label: "Engineering, Procurement ＆ Construction (EPC)",
    value: 91,
    param: "Engineering, Procurement ＆ Construction (EPC)",
  },
  {
    type: "industries",
    label: "Powertrain Combustion",
    value: 92,
    param: "Powertrain Combustion",
  },
  {
    type: "industries",
    label: "Biotechnology Industry",
    value: 93,
    param: "Biotechnology Industry",
  },
  {
    type: "industries",
    label: "IT Consulting Services",
    value: 94,
    param: "IT Consulting Services",
  },
  {
    type: "industries",
    label: "Connected Car",
    value: 95,
    param: "Connected Car",
  },
  { type: "industries", label: "OEM", value: 96, param: "OEM" },
  {
    type: "industries",
    label: "Fixed Broadband",
    value: 97,
    param: "Fixed Broadband",
  },
  {
    type: "industries",
    label: "Fixed Access Technology",
    value: 98,
    param: "Fixed Access Technology",
  },
  { type: "industries", label: "Textile", value: 99, param: "Textile" },
  {
    type: "industries",
    label: "Traffic Management System",
    value: 100,
    param: "Traffic Management System",
  },
  {
    type: "industries",
    label: "Telecom Infrastructure",
    value: 101,
    param: "Telecom Infrastructure",
  },
  {
    type: "industries",
    label: "Rail Infrastructure",
    value: 102,
    param: "Rail Infrastructure",
  },
  {
    type: "industries",
    label: "Mobility Service Provider",
    value: 103,
    param: "Mobility Service Provider",
  },
  {
    type: "industries",
    label: "Automation Equipment",
    value: 104,
    param: "Automation Equipment",
  },
  {
    type: "industries",
    label: "Electricity Generation",
    value: 105,
    param: "Electricity Generation",
  },
  {
    type: "industries",
    label: "Health Insurance",
    value: 106,
    param: "Health Insurance",
  },
  {
    type: "industries",
    label: "Service Provider",
    value: 107,
    param: "Service Provider",
  },
  {
    type: "industries",
    label: "Interior Components",
    value: 108,
    param: "Interior Components",
  },
  {
    type: "industries",
    label: "Shipping ＆ Ports",
    value: 109,
    param: "Shipping ＆ Ports",
  },
  {
    type: "industries",
    label: "Banking Industry",
    value: 110,
    param: "Banking Industry",
  },
  {
    type: "industries",
    label: "Body Components",
    value: 111,
    param: "Body Components",
  },
  {
    type: "industries",
    label: "Energy Transmission ＆ Distribution Products",
    value: 112,
    param: "Energy Transmission ＆ Distribution Products",
  },
  {
    type: "industries",
    label: "Shipbuilding",
    value: 113,
    param: "Shipbuilding",
  },
  {
    type: "industries",
    label: "Food, Beverage ＆ Packaging Machinery",
    value: 114,
    param: "Food, Beverage ＆ Packaging Machinery",
  },
  {
    type: "industries",
    label: "Medtech Industry",
    value: 115,
    param: "Medtech Industry",
  },
  { type: "industries", label: "Aerospace", value: 116, param: "Aerospace" },
  {
    type: "industries",
    label: "Engineering Service Provider",
    value: 117,
    param: "Engineering Service Provider",
  },
  { type: "industries", label: "Dealer", value: 118, param: "Dealer" },
  { type: "industries", label: "Car", value: 119, param: "Car" },
  {
    type: "industries",
    label: "Retail Market",
    value: 120,
    param: "Retail Market",
  },
  { type: "industries", label: "Retailers", value: 121, param: "Retailers" },
  {
    type: "industries",
    label: "Retail ＆ Wholesale",
    value: 122,
    param: "Retail ＆ Wholesale",
  },
  {
    type: "industries",
    label: "Distribution System Operator",
    value: 123,
    param: "Distribution System Operator",
  },
  {
    type: "industries",
    label: "Slow Moving Consumer Goods",
    value: 124,
    param: "Slow Moving Consumer Goods",
  },
  {
    type: "industries",
    label: "Heavy Duty Machinery ＆ Vehicles",
    value: 125,
    param: "Heavy Duty Machinery ＆ Vehicles",
  },
  {
    type: "industries",
    label: "Raw Materials",
    value: 126,
    param: "Raw Materials",
  },
  {
    type: "industries",
    label: "Intermediate Products",
    value: 127,
    param: "Intermediate Products",
  },
  {
    type: "industries",
    label: "Utility Specific Product",
    value: 128,
    param: "Utility Specific Product",
  },
  { type: "industries", label: "Nuclear", value: 129, param: "Nuclear" },
  {
    type: "industries",
    label: "Oil ＆ Gas Midstream",
    value: 130,
    param: "Oil ＆ Gas Midstream",
  },
  {
    type: "industries",
    label: "Energy Sources",
    value: 131,
    param: "Energy Sources",
  },
  {
    type: "industries",
    label: "Oil ＆ Gas Downstream",
    value: 132,
    param: "Oil ＆ Gas Downstream",
  },
  { type: "industries", label: "Refining", value: 133, param: "Refining" },
  { type: "industries", label: "Beverages", value: 134, param: "Beverages" },
  {
    type: "industries",
    label: "Passenger Airline",
    value: 135,
    param: "Passenger Airline",
  },
  {
    type: "industries",
    label: "Fine / Specialty Chemicals",
    value: 136,
    param: "Fine / Specialty Chemicals",
  },
  {
    type: "industries",
    label: "Inorganic Chemicals",
    value: 137,
    param: "Inorganic Chemicals",
  },
  {
    type: "industries",
    label: "Organic Chemicals",
    value: 138,
    param: "Organic Chemicals",
  },
  {
    type: "industries",
    label: "Paints / Coatings",
    value: 139,
    param: "Paints / Coatings",
  },
  {
    type: "industries",
    label: "Petrochemicals",
    value: 140,
    param: "Petrochemicals",
  },
  {
    type: "industries",
    label: "White Goods",
    value: 141,
    param: "White Goods",
  },
  { type: "industries", label: "Apparel", value: 142, param: "Apparel" },
  { type: "industries", label: "Retail", value: 143, param: "Retail" },
  { type: "industries", label: "Building", value: 144, param: "Building" },
  { type: "industries", label: "Media", value: 145, param: "Media" },
  {
    type: "industries",
    label: "Online Media",
    value: 146,
    param: "Online Media",
  },
  {
    type: "industries",
    label: "Fixed Network Services",
    value: 147,
    param: "Fixed Network Services",
  },
  {
    type: "industries",
    label: "Investment Banking",
    value: 148,
    param: "Investment Banking",
  },
  {
    type: "industries",
    label: "Digital Health / e-Health / m-Health",
    value: 149,
    param: "Digital Health / e-Health / m-Health",
  },
  {
    type: "industries",
    label: "Healthcare Providers",
    value: 150,
    param: "Healthcare Providers",
  },
  {
    type: "industries",
    label: "Internet Service Provider (ISP)",
    value: 151,
    param: "Internet Service Provider (ISP)",
  },
  {
    type: "industries",
    label: "International Organization",
    value: 152,
    param: "International Organization",
  },
  {
    type: "industries",
    label: "Local Institution ＆ Administration",
    value: 153,
    param: "Local Institution ＆ Administration",
  },
  {
    type: "industries",
    label: "Mobile Solutions",
    value: 154,
    param: "Mobile Solutions",
  },
  {
    type: "industries",
    label: "Managed Services",
    value: 155,
    param: "Managed Services",
  },
  {
    type: "industries",
    label: "Development ＆ Integration Services",
    value: 156,
    param: "Development ＆ Integration Services",
  },
  { type: "industries", label: "Electric", value: 157, param: "Electric" },
  {
    type: "industries",
    label: "Gaming ＆ Gambling",
    value: 158,
    param: "Gaming ＆ Gambling",
  },
  {
    type: "industries",
    label: "Road Traffic",
    value: 159,
    param: "Road Traffic",
  },
  { type: "industries", label: "Wind", value: 160, param: "Wind" },
  {
    type: "industries",
    label: "Energy Contracting or Services",
    value: 161,
    param: "Energy Contracting or Services",
  },
  { type: "industries", label: "Supplier", value: 162, param: "Supplier" },
  {
    type: "industries",
    label: "Electronics",
    value: 163,
    param: "Electronics",
  },
  {
    type: "industries",
    label: "Distributor",
    value: 164,
    param: "Distributor",
  },
  {
    type: "industries",
    label: "Glass ＆ Ceramics",
    value: 165,
    param: "Glass ＆ Ceramics",
  },
  {
    type: "industries",
    label: "Health ＆ Personal Care",
    value: 166,
    param: "Health ＆ Personal Care",
  },
  {
    type: "industries",
    label: "Exploration",
    value: 167,
    param: "Exploration",
  },
  { type: "industries", label: "Gas", value: 168, param: "Gas" },
  {
    type: "industries",
    label: "Precision Instruments",
    value: 169,
    param: "Precision Instruments",
  },
  {
    type: "industries",
    label: "Microelectronic Components",
    value: 170,
    param: "Microelectronic Components",
  },
  { type: "industries", label: "Minerals", value: 171, param: "Minerals" },
  { type: "industries", label: "E-Mobility", value: 172, param: "E-Mobility" },
  {
    type: "industries",
    label: "Direct Insurance",
    value: 173,
    param: "Direct Insurance",
  },
  {
    type: "industries",
    label: "Pharmaceutical Industry",
    value: 174,
    param: "Pharmaceutical Industry",
  },
  {
    type: "industries",
    label: "Life Insurance",
    value: 175,
    param: "Life Insurance",
  },
  {
    type: "industries",
    label: "Agrochemicals",
    value: 176,
    param: "Agrochemicals",
  },
  {
    type: "industries",
    label: "Content Production",
    value: 177,
    param: "Content Production",
  },
  {
    type: "industries",
    label: "OTT Aggregation",
    value: 178,
    param: "OTT Aggregation",
  },
  { type: "industries", label: "Tourism", value: 179, param: "Tourism" },
  {
    type: "industries",
    label: "Fin Tech Industry",
    value: 180,
    param: "Fin Tech Industry",
  },
  {
    type: "industries",
    label: "Wealth Management",
    value: 181,
    param: "Wealth Management",
  },
  {
    type: "industries",
    label: "Stock Exchange",
    value: 182,
    param: "Stock Exchange",
  },
  {
    type: "industries",
    label: "Semiconductors",
    value: 183,
    param: "Semiconductors",
  },
  {
    type: "industries",
    label: "Waste Management",
    value: 184,
    param: "Waste Management",
  },
  {
    type: "industries",
    label: "Real Estate",
    value: 185,
    param: "Real Estate",
  },
  {
    type: "industries",
    label: "Medical Devices",
    value: 186,
    param: "Medical Devices",
  },
  { type: "industries", label: "Motorcycle", value: 187, param: "Motorcycle" },
  {
    type: "industries",
    label: "Pharmaceuticals Ingredients",
    value: 188,
    param: "Pharmaceuticals Ingredients",
  },
  {
    type: "industries",
    label: "Electrical Components",
    value: 189,
    param: "Electrical Components",
  },
  {
    type: "industries",
    label: "Consumer Electronics",
    value: 190,
    param: "Consumer Electronics",
  },
  { type: "industries", label: "Drivetrain", value: 191, param: "Drivetrain" },
  {
    type: "industries",
    label: "Software Maintenance ＆ Support",
    value: 192,
    param: "Software Maintenance ＆ Support",
  },
  {
    type: "industries",
    label: "Software Product Development",
    value: 193,
    param: "Software Product Development",
  },
  {
    type: "industries",
    label: "Transmission Equipment",
    value: 194,
    param: "Transmission Equipment",
  },
  { type: "industries", label: "Wholesale", value: 195, param: "Wholesale" },
  {
    type: "industries",
    label: "Capital Markets",
    value: 196,
    param: "Capital Markets",
  },
  {
    type: "industries",
    label: "Transaction Banking",
    value: 197,
    param: "Transaction Banking",
  },
  {
    type: "industries",
    label: "Leasing Industry",
    value: 198,
    param: "Leasing Industry",
  },
  {
    type: "industries",
    label: "Transportation Products",
    value: 199,
    param: "Transportation Products",
  },
  {
    type: "industries",
    label: "Machine Tools",
    value: 200,
    param: "Machine Tools",
  },
  {
    type: "industries",
    label: "Energy Legal ＆ Contracting Issues",
    value: 201,
    param: "Energy Legal ＆ Contracting Issues",
  },
  {
    type: "industries",
    label: "Distributed Generation",
    value: 202,
    param: "Distributed Generation",
  },
  { type: "industries", label: "Paper", value: 203, param: "Paper" },
  {
    type: "industries",
    label: "Light Weight Construction",
    value: 204,
    param: "Light Weight Construction",
  },
  {
    type: "industries",
    label: "Nutrition / Functional Food",
    value: 205,
    param: "Nutrition / Functional Food",
  },
  {
    type: "industries",
    label: "Financial Service Provider",
    value: 206,
    param: "Financial Service Provider",
  },
  {
    type: "industries",
    label: "Plastic ＆ Rubber",
    value: 207,
    param: "Plastic ＆ Rubber",
  },
  { type: "industries", label: "Regulation", value: 208, param: "Regulation" },
  {
    type: "industries",
    label: "Distribution ＆ Retail",
    value: 209,
    param: "Distribution ＆ Retail",
  },
  {
    type: "industries",
    label: "Mobile Broadband Wireless Access (MBWA)",
    value: 210,
    param: "Mobile Broadband Wireless Access (MBWA)",
  },
  {
    type: "industries",
    label: "Diagnostics",
    value: 211,
    param: "Diagnostics",
  },
  { type: "industries", label: "Defense", value: 212, param: "Defense" },
  {
    type: "industries",
    label: "Bus / Coach",
    value: 213,
    param: "Bus / Coach",
  },
  {
    type: "industries",
    label: "Therapeutic Indication / Procedure",
    value: 214,
    param: "Therapeutic Indication / Procedure",
  },
  {
    type: "industries",
    label: "Construction Chemicals",
    value: 215,
    param: "Construction Chemicals",
  },
  {
    type: "industries",
    label: "Backbone Network",
    value: 216,
    param: "Backbone Network",
  },
  {
    type: "industries",
    label: "Hardware Maintenance ＆ Support",
    value: 217,
    param: "Hardware Maintenance ＆ Support",
  },
  { type: "industries", label: "CMO / CRO", value: 218, param: "CMO / CRO" },
  {
    type: "industries",
    label: "Proprietary Mobile Radio Networks",
    value: 219,
    param: "Proprietary Mobile Radio Networks",
  },
  {
    type: "industries",
    label: "Fixed Voice",
    value: 220,
    param: "Fixed Voice",
  },
  {
    type: "industries",
    label: "Life Science Analytical Products ＆ Instrumentation",
    value: 221,
    param: "Life Science Analytical Products ＆ Instrumentation",
  },
  { type: "industries", label: "Robotics", value: 222, param: "Robotics" },
  {
    type: "industries",
    label: "Printed Media",
    value: 223,
    param: "Printed Media",
  },
  {
    type: "industries",
    label: "LNG Terminals",
    value: 224,
    param: "LNG Terminals",
  },
  { type: "industries", label: "Cosmetics", value: 225, param: "Cosmetics" },
  {
    type: "industries",
    label: "Industry 4.0",
    value: 226,
    param: "Industry 4.0",
  },
  { type: "industries", label: "Production", value: 227, param: "Production" },
  {
    type: "industries",
    label: "Advertising",
    value: 228,
    param: "Advertising",
  },
  {
    type: "industries",
    label: "Venture Capital",
    value: 229,
    param: "Venture Capital",
  },
  {
    type: "industries",
    label: "Metallic Parts for Manufacturing",
    value: 230,
    param: "Metallic Parts for Manufacturing",
  },
  { type: "industries", label: "Cinema", value: 231, param: "Cinema" },
  {
    type: "industries",
    label: "Mechanical Parts",
    value: 232,
    param: "Mechanical Parts",
  },
  {
    type: "industries",
    label: "Energy Market Liberalization",
    value: 233,
    param: "Energy Market Liberalization",
  },
  { type: "industries", label: "Trucking", value: 234, param: "Trucking" },
  {
    type: "industries",
    label: "Gas Pipelines",
    value: 235,
    param: "Gas Pipelines",
  },
  { type: "industries", label: "Handling", value: 236, param: "Handling" },
  {
    type: "industries",
    label: "Carrier Networks",
    value: 237,
    param: "Carrier Networks",
  },
  {
    type: "industries",
    label: "Satellite Communication",
    value: 238,
    param: "Satellite Communication",
  },
  {
    type: "industries",
    label: "Content ＆ Commerce Services",
    value: 239,
    param: "Content ＆ Commerce Services",
  },
  {
    type: "industries",
    label: "Spare Parts ＆ Accessories",
    value: 240,
    param: "Spare Parts ＆ Accessories",
  },
  {
    type: "industries",
    label: "Fiber Weaves",
    value: 241,
    param: "Fiber Weaves",
  },
  { type: "industries", label: "Smart Home", value: 242, param: "Smart Home" },
  {
    type: "industries",
    label: "Internet of Things",
    value: 243,
    param: "Internet of Things",
  },
  { type: "industries", label: "Radio", value: 244, param: "Radio" },
  { type: "industries", label: "Video", value: 245, param: "Video" },
  {
    type: "industries",
    label: "Cooling System",
    value: 246,
    param: "Cooling System",
  },
  {
    type: "industries",
    label: "Entertainment",
    value: 247,
    param: "Entertainment",
  },
  {
    type: "industries",
    label: "Travel Agencies ＆ Operators",
    value: 248,
    param: "Travel Agencies ＆ Operators",
  },
  {
    type: "industries",
    label: "Food / Feed Additives",
    value: 249,
    param: "Food / Feed Additives",
  },
  {
    type: "industries",
    label: "Video Broadcast (Cable or Satellite)",
    value: 250,
    param: "Video Broadcast (Cable or Satellite)",
  },
  {
    type: "industries",
    label: "Oil ＆ Products Pipelines",
    value: 251,
    param: "Oil ＆ Products Pipelines",
  },
  {
    type: "industries",
    label: "Plastics / Polymers",
    value: 252,
    param: "Plastics / Polymers",
  },
  { type: "industries", label: "CDMO / CRO", value: 253, param: "CDMO / CRO" },
  {
    type: "industries",
    label: "Non-Life Insurance",
    value: 254,
    param: "Non-Life Insurance",
  },
  { type: "industries", label: "Big Pharma", value: 255, param: "Big Pharma" },
  {
    type: "industries",
    label: "Car Sharing",
    value: 256,
    param: "Car Sharing",
  },
  {
    type: "industries",
    label: "Multimodal Mobility",
    value: 257,
    param: "Multimodal Mobility",
  },
  {
    type: "industries",
    label: "Smart Cities",
    value: 258,
    param: "Smart Cities",
  },
  {
    type: "industries",
    label: "Payment Solution Industry",
    value: 259,
    param: "Payment Solution Industry",
  },
  {
    type: "industries",
    label: "Facility Management",
    value: 260,
    param: "Facility Management",
  },
  {
    type: "industries",
    label: "Central Generation",
    value: 261,
    param: "Central Generation",
  },
  {
    type: "industries",
    label: "Re-Insurance",
    value: 262,
    param: "Re-Insurance",
  },
  {
    type: "industries",
    label: "Transmission System Operator",
    value: 263,
    param: "Transmission System Operator",
  },
  { type: "industries", label: "Chassis", value: 264, param: "Chassis" },
  {
    type: "industries",
    label: "Animal Health Industry",
    value: 265,
    param: "Animal Health Industry",
  },
  {
    type: "industries",
    label: "Security Equipment",
    value: 266,
    param: "Security Equipment",
  },
  {
    type: "industries",
    label: "Mobile Wholesale",
    value: 267,
    param: "Mobile Wholesale",
  },
  {
    type: "industries",
    label: "Association",
    value: 268,
    param: "Association",
  },
  { type: "industries", label: "Music", value: 269, param: "Music" },
  {
    type: "industries",
    label: "Biobased Chemicals",
    value: 270,
    param: "Biobased Chemicals",
  },
  { type: "industries", label: "eCommerce", value: 271, param: "eCommerce" },
  {
    type: "industries",
    label: "Regulatory Institutions",
    value: 272,
    param: "Regulatory Institutions",
  },
  {
    type: "industries",
    label: "National Regulation",
    value: 273,
    param: "National Regulation",
  },
  {
    type: "industries",
    label: "Plastic Processing Machinery",
    value: 274,
    param: "Plastic Processing Machinery",
  },
  {
    type: "industries",
    label: "Wireless LAN",
    value: 275,
    param: "Wireless LAN",
  },
  {
    type: "industries",
    label: "Charging Service Provider",
    value: 276,
    param: "Charging Service Provider",
  },
  { type: "industries", label: "Battery", value: 277, param: "Battery" },
  {
    type: "industries",
    label: "Charging Infrastructure",
    value: 278,
    param: "Charging Infrastructure",
  },
  {
    type: "industries",
    label: "Off-highway Engines",
    value: 279,
    param: "Off-highway Engines",
  },
  {
    type: "industries",
    label: "Financial Sponsors",
    value: 280,
    param: "Financial Sponsors",
  },
  { type: "industries", label: "Biologics", value: 281, param: "Biologics" },
  {
    type: "industries",
    label: "Heating System",
    value: 282,
    param: "Heating System",
  },
  {
    type: "industries",
    label: "Energy Prices ＆ Tariffs",
    value: 283,
    param: "Energy Prices ＆ Tariffs",
  },
  {
    type: "industries",
    label: "Home Maintenance",
    value: 284,
    param: "Home Maintenance",
  },
  {
    type: "industries",
    label: "Furnishings",
    value: 285,
    param: "Furnishings",
  },
  { type: "industries", label: "Trading", value: 286, param: "Trading" },
  { type: "industries", label: "Hotels", value: 287, param: "Hotels" },
  { type: "industries", label: "Coal", value: 288, param: "Coal" },
  {
    type: "industries",
    label: "Neurological",
    value: 289,
    param: "Neurological",
  },
  {
    type: "industries",
    label: "Cargo Airline",
    value: 290,
    param: "Cargo Airline",
  },
  { type: "industries", label: "MRO", value: 291, param: "MRO" },
  {
    type: "industries",
    label: "Other 3rd Party Service Providers",
    value: 292,
    param: "Other 3rd Party Service Providers",
  },
  {
    type: "industries",
    label: "Genomics / Genetics",
    value: 293,
    param: "Genomics / Genetics",
  },
  { type: "industries", label: "Hydro", value: 294, param: "Hydro" },
  {
    type: "industries",
    label: "Facilities Management",
    value: 295,
    param: "Facilities Management",
  },
  {
    type: "industries",
    label: "Point to Multipoint",
    value: 296,
    param: "Point to Multipoint",
  },
  { type: "industries", label: "Biomass", value: 297, param: "Biomass" },
  {
    type: "industries",
    label: "Printing Machinery",
    value: 298,
    param: "Printing Machinery",
  },
  {
    type: "industries",
    label: "Transaction Processing",
    value: 299,
    param: "Transaction Processing",
  },
  {
    type: "industries",
    label: "Credit ＆ Debt Card",
    value: 300,
    param: "Credit ＆ Debt Card",
  },
  {
    type: "industries",
    label: "Mobile Payment",
    value: 301,
    param: "Mobile Payment",
  },
  {
    type: "industries",
    label: "Industry Associations / Clusters",
    value: 302,
    param: "Industry Associations / Clusters",
  },
  { type: "industries", label: "Hydrogen", value: 303, param: "Hydrogen" },
  {
    type: "industries",
    label: "Life Science Production Systems",
    value: 304,
    param: "Life Science Production Systems",
  },
  {
    type: "industries",
    label: "Remote Monitoring",
    value: 305,
    param: "Remote Monitoring",
  },
  {
    type: "industries",
    label: "Global Pharma",
    value: 306,
    param: "Global Pharma",
  },
  {
    type: "industries",
    label: "Cell and Gene Therapy",
    value: 307,
    param: "Cell and Gene Therapy",
  },
  {
    type: "industries",
    label: "Life Science Assays",
    value: 308,
    param: "Life Science Assays",
  },
  {
    type: "industries",
    label: "Household Products",
    value: 309,
    param: "Household Products",
  },
  {
    type: "industries",
    label: "Importer ＆ National Sales Company",
    value: 310,
    param: "Importer ＆ National Sales Company",
  },
  {
    type: "industries",
    label: "Mid-sized Pharma",
    value: 311,
    param: "Mid-sized Pharma",
  },
  { type: "industries", label: "Cancer", value: 312, param: "Cancer" },
  {
    type: "industries",
    label: "Rotating Equipment",
    value: 313,
    param: "Rotating Equipment",
  },
  { type: "industries", label: "Powerline", value: 314, param: "Powerline" },
  { type: "industries", label: "Drives", value: 315, param: "Drives" },
  {
    type: "industries",
    label: "Hydrogen Fuel Cell",
    value: 316,
    param: "Hydrogen Fuel Cell",
  },
  {
    type: "industries",
    label: "Water / Sewage",
    value: 317,
    param: "Water / Sewage",
  },
  { type: "industries", label: "OTC", value: 318, param: "OTC" },
  {
    type: "industries",
    label: "Commercial Banking",
    value: 319,
    param: "Commercial Banking",
  },
  {
    type: "industries",
    label: "Exchange Industry",
    value: 320,
    param: "Exchange Industry",
  },
  { type: "industries", label: "Appliances", value: 321, param: "Appliances" },
  { type: "industries", label: "Wood", value: 322, param: "Wood" },
  { type: "industries", label: "Parcels", value: 323, param: "Parcels" },
  {
    type: "industries",
    label: "Market Rules",
    value: 324,
    param: "Market Rules",
  },
  {
    type: "industries",
    label: "Healthcare Payers",
    value: 325,
    param: "Healthcare Payers",
  },
  {
    type: "industries",
    label: "Security of Supply",
    value: 326,
    param: "Security of Supply",
  },
  {
    type: "industries",
    label: "Market Design",
    value: 327,
    param: "Market Design",
  },
  {
    type: "industries",
    label: "Project Development",
    value: 328,
    param: "Project Development",
  },
  {
    type: "industries",
    label: "Neutraceuticals",
    value: 329,
    param: "Neutraceuticals",
  },
  {
    type: "industries",
    label: "Access Control Equipment",
    value: 330,
    param: "Access Control Equipment",
  },
  { type: "industries", label: "Unbundling", value: 331, param: "Unbundling" },
  {
    type: "industries",
    label: "Asset Management",
    value: 332,
    param: "Asset Management",
  },
  {
    type: "industries",
    label: "Highway Services",
    value: 333,
    param: "Highway Services",
  },
  {
    type: "industries",
    label: "Universal Banking",
    value: 334,
    param: "Universal Banking",
  },
  {
    type: "industries",
    label: "Wholesale Market",
    value: 335,
    param: "Wholesale Market",
  },
  {
    type: "industries",
    label: "Blood Products",
    value: 336,
    param: "Blood Products",
  },
  {
    type: "industries",
    label: "Pension Funds",
    value: 337,
    param: "Pension Funds",
  },
  {
    type: "industries",
    label: "Tobacco Products",
    value: 338,
    param: "Tobacco Products",
  },
  {
    type: "industries",
    label: "New Treatment Modalities",
    value: 339,
    param: "New Treatment Modalities",
  },
  { type: "industries", label: "Vaccines", value: 340, param: "Vaccines" },
  {
    type: "industries",
    label: "Energy & Utilities",
    value: 341,
    param: "Energy & Utilities",
  },
  {
    type: "industries",
    label: "Engineering & Manufacturing",
    value: 342,
    param: "Engineering & Manufacturing",
  },
  {
    type: "industries",
    label: "Public Sector & Services",
    value: 343,
    param: "Public Sector & Services",
  },
  {
    type: "industries",
    label: "Travel & Transportation",
    value: 345,
    param: "Travel & Transportation",
  },
  {
    type: "industries",
    label: "Aftermarket Parts & Service Provider",
    value: 346,
    param: "Aftermarket Parts & Service Provider",
  },
  {
    type: "industries",
    label: "Importer & National Sales Company",
    value: 347,
    param: "Importer & National Sales Company",
  },
  {
    type: "industries",
    label: "Spare Parts & Accessories",
    value: 348,
    param: "Spare Parts & Accessories",
  },
  {
    type: "industries",
    label: "Retail & Wholesale",
    value: 349,
    param: "Retail & Wholesale",
  },
  {
    type: "industries",
    label: "Energy Legal & Contracting Issues",
    value: 350,
    param: "Energy Legal & Contracting Issues",
  },
  {
    type: "industries",
    label: "Oil & Gas Downstream",
    value: 351,
    param: "Oil & Gas Downstream",
  },
  {
    type: "industries",
    label: "Oil & Gas Midstream",
    value: 352,
    param: "Oil & Gas Midstream",
  },
  {
    type: "industries",
    label: "Oil & Gas Regulation & Legal Support",
    value: 353,
    param: "Oil & Gas Regulation & Legal Support",
  },
  {
    type: "industries",
    label: "Oil & Gas Upstream",
    value: 354,
    param: "Oil & Gas Upstream",
  },
  {
    type: "industries",
    label: "Engineering, Procurement & Construction (EPC)",
    value: 355,
    param: "Engineering, Procurement & Construction (EPC)",
  },
  {
    type: "industries",
    label: "Heavy Duty Machinery & Vehicles",
    value: 356,
    param: "Heavy Duty Machinery & Vehicles",
  },
  {
    type: "industries",
    label: "Products & Devices",
    value: 357,
    param: "Products & Devices",
  },
  {
    type: "industries",
    label: "Local Institution & Administration",
    value: 358,
    param: "Local Institution & Administration",
  },
  {
    type: "industries",
    label: "National Institution & Administration",
    value: 359,
    param: "National Institution & Administration",
  },
  {
    type: "industries",
    label: "Rail & Local Public Transport",
    value: 360,
    param: "Rail & Local Public Transport",
  },
  {
    type: "industries",
    label: "Shipping & Ports",
    value: 361,
    param: "Shipping & Ports",
  },
  {
    type: "industries",
    label: "Health & Personal Care",
    value: 362,
    param: "Health & Personal Care",
  },
  {
    type: "industries",
    label: "Distribution & Retail",
    value: 363,
    param: "Distribution & Retail",
  },
  {
    type: "industries",
    label: "Oil & Products Pipelines",
    value: 364,
    param: "Oil & Products Pipelines",
  },
  {
    type: "industries",
    label: "LNG Production",
    value: 365,
    param: "LNG Production",
  },
  {
    type: "industries",
    label: "Energy Prices & Tariffs",
    value: 366,
    param: "Energy Prices & Tariffs",
  },
  {
    type: "industries",
    label: "Paints & Coatings",
    value: 367,
    param: "Paints & Coatings",
  },
  {
    type: "industries",
    label: "Plastic & Rubber",
    value: 368,
    param: "Plastic & Rubber",
  },
  {
    type: "industries",
    label: "Energy Transmission & Distribution Products",
    value: 369,
    param: "Energy Transmission & Distribution Products",
  },
  {
    type: "industries",
    label: "Food, Beverage & Packaging Machinery",
    value: 370,
    param: "Food, Beverage & Packaging Machinery",
  },
  {
    type: "industries",
    label: "Glass & Ceramics",
    value: 371,
    param: "Glass & Ceramics",
  },
  {
    type: "industries",
    label: "Private Banking",
    value: 372,
    param: "Private Banking",
  },
  {
    type: "industries",
    label: "Algo & High Frequency Trading",
    value: 373,
    param: "Algo & High Frequency Trading",
  },
  {
    type: "industries",
    label: "Central Securities Depository",
    value: 374,
    param: "Central Securities Depository",
  },
  {
    type: "industries",
    label: "Derivatives Exchange",
    value: 375,
    param: "Derivatives Exchange",
  },
  {
    type: "industries",
    label: "Market Data & Analytics Provider",
    value: 376,
    param: "Market Data & Analytics Provider",
  },
  {
    type: "industries",
    label: "Multilateral Trading Facilities",
    value: 377,
    param: "Multilateral Trading Facilities",
  },
  {
    type: "industries",
    label: "Distressed Debt",
    value: 378,
    param: "Distressed Debt",
  },
  {
    type: "industries",
    label: "Hedge Funds",
    value: 379,
    param: "Hedge Funds",
  },
  {
    type: "industries",
    label: "Seed Capital",
    value: 380,
    param: "Seed Capital",
  },
  {
    type: "industries",
    label: "Credit & Debt Card",
    value: 381,
    param: "Credit & Debt Card",
  },
  {
    type: "industries",
    label: "P2P Lending",
    value: 382,
    param: "P2P Lending",
  },
  {
    type: "industries",
    label: "International Regulation",
    value: 383,
    param: "International Regulation",
  },
  {
    type: "industries",
    label: "Life Science Analytical Products & Instrumentation",
    value: 384,
    param: "Life Science Analytical Products & Instrumentation",
  },
  {
    type: "industries",
    label: "Life science Assays",
    value: 385,
    param: "Life science Assays",
  },
  {
    type: "industries",
    label: "Prescription Only Medicine / Generics",
    value: 386,
    param: "Prescription Only Medicine / Generics",
  },
  {
    type: "industries",
    label: "Prescription Only Medicine / Biosimilars",
    value: 387,
    param: "Prescription Only Medicine / Biosimilars",
  },
  {
    type: "industries",
    label: "Prescription Only Medicine / Patented",
    value: 388,
    param: "Prescription Only Medicine / Patented",
  },
  {
    type: "industries",
    label: "Alimentary / Metabolic",
    value: 389,
    param: "Alimentary / Metabolic",
  },
  {
    type: "industries",
    label: "Anti-Infection",
    value: 390,
    param: "Anti-Infection",
  },
  {
    type: "industries",
    label: "Antiparasitic",
    value: 391,
    param: "Antiparasitic",
  },
  { type: "industries", label: "Hematology", value: 392, param: "Hematology" },
  {
    type: "industries",
    label: "Cardiovascular",
    value: 393,
    param: "Cardiovascular",
  },
  { type: "industries", label: "Dentistry", value: 394, param: "Dentistry" },
  {
    type: "industries",
    label: "Dermatological",
    value: 395,
    param: "Dermatological",
  },
  {
    type: "industries",
    label: "Genitourinary",
    value: 396,
    param: "Genitourinary",
  },
  { type: "industries", label: "Hormonal", value: 397, param: "Hormonal" },
  {
    type: "industries",
    label: "Immunological",
    value: 398,
    param: "Immunological",
  },
  {
    type: "industries",
    label: "Muscoskeletal",
    value: 399,
    param: "Muscoskeletal",
  },
  { type: "industries", label: "Oncology", value: 400, param: "Oncology" },
  {
    type: "industries",
    label: "Rare Diseases",
    value: 401,
    param: "Rare Diseases",
  },
  {
    type: "industries",
    label: "Respiratory",
    value: 402,
    param: "Respiratory",
  },
  { type: "industries", label: "Sensory", value: 403, param: "Sensory" },
  {
    type: "industries",
    label: "Development & Integration Services",
    value: 404,
    param: "Development & Integration Services",
  },
  {
    type: "industries",
    label: "Hardware Maintenance & Support",
    value: 405,
    param: "Hardware Maintenance & Support",
  },
  {
    type: "industries",
    label: "Software Maintenance & Support",
    value: 406,
    param: "Software Maintenance & Support",
  },
  {
    type: "industries",
    label: "Gaming & Gambling",
    value: 407,
    param: "Gaming & Gambling",
  },
  {
    type: "industries",
    label: "Content & Commerce Services",
    value: 408,
    param: "Content & Commerce Services",
  },
  {
    type: "industries",
    label: "Travel Agencies & Operators",
    value: 409,
    param: "Travel Agencies & Operators",
  },
  {
    type: "industries",
    label: "Market Data ＆ Analytics Provider",
    value: 410,
    param: "Market Data ＆ Analytics Provider",
  },
  {
    type: "industries",
    label: "Algo ＆ High Frequency Trading",
    value: 411,
    param: "Algo ＆ High Frequency Trading",
  },
];

export default industries;
