import axiosInstance from "../../axiosConfig";

export const fetchPrivacyLevels = async () => {
  try {
    const result: any = await axiosInstance.get(
      `documents/privacy-types`,
      {}
    );
    return result.data.results;
  } catch (error) {
    console.error("Error fetching levels:", error);
  }
};

export const fetchCategories = async () => {
  try {
    const result: any = await axiosInstance.get(`documents/categories`, {});
    return result.data.results;
  } catch (error) {
    console.error("Error fetching levels:", error);
  }
};

export const getUploadUrl = async (
  deliveryEngagementId: string,
  fileName: string,
  fileCategory: number,
  privacyLevelsQueryString: []
) => {
  try {
    const response = await axiosInstance.get(
      `documents/upload-urls?deliveryEngagementId=${deliveryEngagementId}&fileName=${encodeURIComponent(
        fileName
      )}&categoryId=${fileCategory}&${privacyLevelsQueryString.toString()
        .replace(/,/g, "&")}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching file upload URL:", error);
    throw error;
  }
};

export const uploadFile = async (file: File, url: string) => {
  try {
    const blob = new Blob([file], { type: file.type });
    const response = await axiosInstance.put(url, blob, {
      headers: {
        "Content-Type": "application/octet-stream",
        "x-ms-blob-type": "BlockBlob",
      },
    });
    if (response.status !== 201) {
      throw new Error("File upload failed");
    }
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
