import React from "react";
import { v4 } from "uuid";
import { Box, useTheme } from "@mui/material";
import { getStatusColour } from "../../utils";

export const HorzontalTimeLine = ({ data, limit = 10 }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "80px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
        marginBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {data.map((item, index) => {
        if (index < limit) {
          return (
            <React.Fragment key={v4()}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: getStatusColour(item.status),
                    borderRadius: "5px",
                  }}
                ></Box>

                <Box sx={{ position: "absolute", top: "30px" }}>
                  {item.renderer}
                </Box>
              </Box>

              {index + 1 !== limit && (
                <Box
                  sx={{
                    position: "relative",
                    height: "4px",
                    width: "100%",
                    backgroundColor: theme.palette.grey[400],
                    borderRadius: "5px",
                    borderRight: "none",
                  }}
                ></Box>
              )}
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};

export default HorzontalTimeLine;
