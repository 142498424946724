import { Box, useTheme } from "@mui/material";
import React from "react";
import { v4 } from "uuid";

export const VerticalTimeLine = ({ data, limit = 10 }: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: "10px",
        alignItems: "flex-start",
        marginBottom: "10px",
      }}
    >
      {data.map((item, index) => {
        if (index < limit) {
          return (
            <Box
              key={v4()}
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: theme.palette.success.main,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    color: theme.palette.common.white,
                  }}
                ></Box>

                {index < data.length - 1 && index < limit - 1 && (
                  <Box
                    sx={{
                      width: "4px",
                      height: "100%",
                      backgroundColor: theme.palette.grey[400],
                      borderRadius: "5px",
                      flex: 1,
                    }}
                  ></Box>
                )}
              </Box>
              <Box sx={{ flex: 1, paddingBottom: "20px" }}>{item.renderer}</Box>
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default VerticalTimeLine;
