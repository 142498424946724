import { Alert, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Show } from "@adl/smart-ui-library";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  selectActivitesState,
  fetchCaseActivitiesById,
  selectDeliveryEngagementId,
  selectUploadComplete,
} from "@/redux/casesSlice";
import { formatDate } from "@/utils";
import ActivitiesRendererItem from "./ActivitiesRenderItem";
import TimeLine, { ITimeLineData } from "@/components/TimeLine/TimeLine";
import { IActivity } from "@/redux/store.types";
import LoadingScreen from "@/components/LoadingScreen";
import { motion } from "framer-motion";
import { ANNIMATION_SPEED } from "../../constants";

const Activities = () => {
  const appDispatch = useAppDispatch();
  const activities = useAppSelector(selectActivitesState);
  const deliveryEngagementId = useAppSelector(selectDeliveryEngagementId);
  const isFileUploadComplete = useAppSelector(selectUploadComplete);

  const { data } = activities;

  const [processedData, setProcessedData] = React.useState<
    ITimeLineData[] | null
  >(null);

  useEffect(() => {
    const parsedActivities = data?.map((item: IActivity, index: number) => {
      return {
        id: index,
        renderer: (
          <ActivitiesRendererItem
            activity={item.activityType.name}
            name={item.metaData.fileName}
            date={formatDate(item.createdAt.split("T")[0])}
            user={item.user.name}
          />
        ),
        status: { present: true, verified: true },
      };
    });
    setProcessedData(parsedActivities);
  }, [data]);

  const refreshData = async () => {
    appDispatch(
      fetchCaseActivitiesById({
        deliveryEngagementId: deliveryEngagementId,
      })
    );
  };

  useEffect(() => {
    deliveryEngagementId && refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryEngagementId]);

  useEffect(() => {
    isFileUploadComplete && refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileUploadComplete]);

  return (
    <Box>
      <Typography variant="h6">Activities</Typography>
      <Show>
        <Show.When isTrue={Boolean(processedData)}>
          <Show>
            <Show.When isTrue={processedData && processedData?.length > 0}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: ANNIMATION_SPEED }}
              >
                <Box sx={{ padding: "20px 0px" }}>
                  <TimeLine direction="vertical" data={processedData} />
                </Box>
              </motion.div>
            </Show.When>
            <Show.Else>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: ANNIMATION_SPEED }}
              >
                <Alert severity="info">No Activities Recorded</Alert>
              </motion.div>
            </Show.Else>
          </Show>
        </Show.When>
        <Show.Else>
          <LoadingScreen />
        </Show.Else>
      </Show>
    </Box>
  );
};

export default Activities;
