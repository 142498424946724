import React, { SyntheticEvent, useEffect } from "react";
import { selectDocumentPrivacyLevels } from "@/redux/casesSlice";
import { useAppSelector } from "@/hooks";
import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { IDocumentPrivacy } from "@/redux/store.types";
import { v4 } from "uuid";
import _ from "lodash";

type Props = {
  values: IDocumentPrivacy[];
  onUpdate: (values: IDocumentPrivacy[]) => void;
};

const PrivacyLevels = ({ values, onUpdate }: Props) => {
  const levels = useAppSelector(selectDocumentPrivacyLevels);

  const onChange = (e: any) => {
    const updatedState = values.map((item) => {
      if (item.privacyTypeId === Number(e.target.value)) {
        return { ...item, privacyTypeValue: e.target.checked };
      }
      return item;
    });
    onUpdate(updatedState);
  };

  return (
    <FormControl>
      {levels?.map((level: IDocumentPrivacy) => (
        <FormControlLabel
          key={v4()}
          id={level.privacyTypeId}
          onChange={onChange}
          value={level.privacyTypeId}
          control={
            <Switch
              checked={
                values.find(
                  (i: IDocumentPrivacy) =>
                    i.privacyTypeId === level.privacyTypeId
                )?.privacyTypeValue
              }
            />
          }
          label={`Protect ${level.name}`}
        />
      ))}
    </FormControl>
  );
};
export default PrivacyLevels;
