import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Modal } from "@adl/smart-ui-library";

import { v4 } from "uuid";

const FileMetaModal = ({
  onClose,
  onCancel,
  data,
  index,
  total,
  categories,
  privacyLevels,
}) => {
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [fileCategory, setFileCategory] = React.useState(1);
  const [privacyLevel, setPrivacyLevel] = React.useState([
    { privacyTypeId: 1, privacyTypeValue: true },
    { privacyTypeId: 2, privacyTypeValue: true },
    { privacyTypeId: 3, privacyTypeValue: true },
    { privacyTypeId: 4, privacyTypeValue: true },
  ]);

  const handleFileCategory = (event) => {
    setFileCategory(event.target.value);
  };

  const handlePrivacy = (event, value) => {
    const replaceObj = {
      privacyTypeId: value,
      privacyTypeValue: event.target.checked,
    };

    const index = privacyLevel.findIndex(
      (obj) => obj.privacyTypeId === replaceObj.privacyTypeId
    );
    if (index !== -1) {
      privacyLevel.splice(index, 1, replaceObj);
    }
    setPrivacyLevel(privacyLevel);
  };
  useEffect(() => {
    setFileCategory(1);
  }, [data]);

  function getStyles() {
    return {
      textAlign: "left",
      borderRadius: "20px",
      padding: "1px 3px",
      fontSize: "14px",
    };
  }
  return (
    <Modal open={true} onClose={onCancel} name="delete-history-confirmation">
      <Modal.Header>Document Settings</Modal.Header>
      <Modal.Content>
        <Typography variant="body1" sx={{ width: "100%", textAlign: "left" }}>
          File {index + 1} of {total}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <TextField
            label="File Name"
            value={data?.file ? data?.file.name : ""}
          />
          <FormControl>
            <InputLabel id="fileCategory">File category</InputLabel>
            <Select
              labelId="fileCategory"
              id="fileCategory"
              value={fileCategory}
              onChange={handleFileCategory}
              style={getStyles()}
            >
              {categories.map((category) => (
                <MenuItem
                  key={category.documentCategoryId}
                  value={category.documentCategoryId}
                >
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormGroup>
            {privacyLevels &&
              privacyLevels.map((level) => (
                <FormControlLabel
                  key={v4()}
                  id={level.privacyTypeId}
                  onChange={(e) => handlePrivacy(e, level.privacyTypeId)}
                  control={<Switch defaultChecked />}
                  label={`Protect ${level.name}`}
                />
              ))}
          </FormGroup>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="outlined"
          disableElevation
          onClick={onCancel}
          sx={{ width: "100%" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          disabled={disableSubmit}
          onClick={() => {
            setDisableSubmit(true);
            onClose({
              fileCategory,
              privacyLevel,
            })
          }
          }
          sx={{ width: "100%" }}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default FileMetaModal;
