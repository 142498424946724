import { IFilterMenu } from "@adl/smart-ui-library";
import markets from "@/constants/markets";
import industries from "@/constants/industries";
import methodology from "@/constants/methodologies";
import currencies from "@/constants/currencies";

export interface IMetaData {
  id: string;
  options: IFilterMenu[];
  value: IFilterMenu[];
}

export const rows = [
  {
    id: "Industries",
    options: industries,
    value: [
      {
        type: "industries",
        label: "Automotive",
        value: 9,
        param: "Automotive",
      },
    ],
  },
  {
    id: "Methodologies",
    options: methodology,
    value: [
      {
        type: "methodology",
        label: "User Experience",
        value: 263,
        param: "User Experience",
      },
    ],
  },
  {
    id: "Markets",
    options: markets,
    value: [
      {
        type: "markets",
        label: "Sweden",
        value: 12,
        param: "Sweden",
      },
    ],
  },
  {
    id: "Budget Currency",
    options: currencies,
    value: [
      {
        type: "currency",
        label: "USD",
        value: 1,
        param: "USD",
      },
    ],
  },
];
