import React from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import { IMetaData, rows } from "./CaseDetails.data";
import { v4 } from "uuid";
import { Filter } from "@adl/smart-ui-library";

const CaseDetails = () => {
  const [data, setData] = React.useState<IMetaData[]>(rows);
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: "15px" }}>
        Case Details
      </Typography>
      <TableContainer component={Box}>
        <Table
          sx={{
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow
                  key={v4()}
                  sx={{
                    "td, th": {
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    },
                  }}
                >
                  <TableCell sx={{ width: "150px" }}>
                    <Typography variant="h3">{row.id}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {row.value.map((item) => (
                        <Chip key={v4()} label={item.label} />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Filter
                      title={`Add`}
                      onApplyFilter={(filters) => {
                        const newData: IMetaData = { ...row };
                        newData.value = filters;
                        const clone: IMetaData[] = [...data];
                        const index = clone.findIndex(
                          (item) => item.id === row.id
                        );
                        clone[index] = newData;
                        setData(clone);
                      }}
                      multiple={row.id === "Budget Currency" ? false : true}
                      search
                      selectedItems={row.value}
                      config={row.options}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default CaseDetails;
