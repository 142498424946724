import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import { ITableFilter } from "@/redux/casesSlice";
import { useTheme } from "@mui/material";
import { useDebounce } from "@/hooks";

type Props = {
  data: any;
  columns: any;
  totalPages: number;
  pageSize: number;
  pageNumber: number;
  onSelectRow?: (row: any) => void;
  onFilterChange?: (filter: ITableFilter[]) => void;
  onSortChange?: (sort: any) => void;
  onPaginationChange?: (pagination: number) => void;
  debounceTime?: number;
  isLoading: boolean;
  height?: number | string;
  showHeader?: boolean;
};

const Table = ({
  data,
  columns,
  isLoading,
  onSelectRow,
  totalPages = 0,
  pageSize = 10,
  pageNumber = 1,
  onFilterChange,
  debounceTime = 1000,
  onSortChange,
  onPaginationChange,
  height = 600,
  showHeader = true,
}: Props) => {
  const [filterString, setFilterString] = useState<any[] | null>(null);
  const debouncedSearchTerm = useDebounce(filterString, debounceTime);
  const theme = useTheme();

  useEffect(() => {
    if (debouncedSearchTerm) {
      const args: ITableFilter[] = debouncedSearchTerm.map((item) => {
        return {
          field: item.field,
          operator: item.operator,
          value: item.value || "",
        };
      });
      onFilterChange && onFilterChange(args);
    }
  }, [debouncedSearchTerm]);

  const tableProps: any = {};
  if (!showHeader) {
    tableProps.columnHeaderHeight = 0;
  }

  return (
    <DataGrid
      rows={data}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
      }}
      {...tableProps}
      onRowClick={(params) => onSelectRow && onSelectRow(params.row)}
      getRowHeight={() => "auto"}
      getRowId={() => v4()}
      pageSizeOptions={[10]}
      sortingOrder={["asc", "desc", null]}
      filterMode="server"
      onFilterModelChange={(params) => {
        if (params.items.length === 0) {
          setFilterString(null);
          onFilterChange && onFilterChange([]);
        } else {
          setFilterString(params.items);
        }
      }}
      sortingMode="server"
      onSortModelChange={(params) => {
        const args = {};
        if (params.length > 0) {
          args["sort"] = `${params[0].sort === "desc" ? "-" : ""}${
            params[0].field
          }`;
        }
        onSortChange && onSortChange(args);
      }}
      loading={isLoading}
      rowCount={totalPages}
      paginationModel={{
        pageSize: pageSize,
        page: pageNumber,
      }}
      paginationMode="server"
      onPaginationModelChange={(params) => {
        onPaginationChange && onPaginationChange(params.page * params.pageSize);
      }}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
      }
      disableRowSelectionOnClick
      sx={{
        minHeight: height,
        maxHeight: height,
        border: `1px solid ${theme.palette.grey[400]}`,

        "& .MuiDataGrid-columnHeader": {
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          color: theme.palette.primary[700],
        },
        "& .odd-row": {
          backgroundColor: theme.palette.grey[100],
        },
        "& .even-row": {
          backgroundColor: theme.palette.grey[300],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          display: totalPages === 0 ? "none" : "block",
        },
        "& .MuiDataGrid-virtualScroller": {
          overflowY: "scroll",
        },
        "& .MuiDataGrid-cell": {
          padding: "10px 16px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        },
        "& .MuiDataGrid-root": {
          //animation: "fadeIn 0.5s ease-in",
        },
        "& .MuiDataGrid-row": {
          animation: "fadeIn 0.25s ease-in",
        },
        "@keyframes fadeIn": {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
      }}
    />
  );
};

export default Table;
