import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Modal } from "@adl/smart-ui-library";

const FileUploadStatusModal = ({ onClose, children }) => {
  return (
    <Modal open={true} onClose={() => null} name="delete-history-confirmation">
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Button variant="contained" disableElevation onClick={onClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileUploadStatusModal;
