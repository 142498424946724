import { IMenuItem, Select } from "@adl/smart-ui-library";
import { FormControl } from "@mui/material";
import React, { useEffect } from "react";
import { useAppSelector } from "@/hooks";
import { selectDocumentCatagories } from "@/redux/casesSlice";

type Props = {
  value: string | number;
  onUpdate: (value: { id: string | number; name: string }) => void;
};

const EditableCategories = ({ value, onUpdate }: Props) => {
  const [listItems, setListItems] = React.useState<IMenuItem[]>();
  const categories = useAppSelector(selectDocumentCatagories);

  useEffect(() => {
    const items: IMenuItem[] = [];
    categories.map((item) => {
      items.push({
        id: item.documentCategoryId,
        name: item.name,
        icon: null,
        value: item.documentCategoryId,
        tooltip: "",
        dataTestId: "",
      });
    });
    setListItems(items);
  }, []);

  if (!listItems) return null;

  return (
    <FormControl>
      <Select
        activeTabIndex={value}
        onChange={(e) => {
          const result = listItems.find((item) => item.id === e);
          result && onUpdate({ id: result.id, name: result.name });
        }}
        list={listItems}
      ></Select>
    </FormControl>
  );
};

export default EditableCategories;
