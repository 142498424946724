import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "@/axiosConfig";
import {
  IActivity,
  ICase,
  ICaseDetails,
  IDocument,
  IPageination,
  IStatuses,
  ITableFilter,
  IServiceResponce,
  ICategories,
  ICasePrivacy,
  IDocumentPrivacy,
  ICasePayload,
  ICaseEditsState,
} from "./store.types";

export const NAME = "cases";

interface IState {
  cases: IServiceResponce<ICase>;
  profile: IServiceResponce<ICaseDetails>;
  status: IStatuses | null;
  activites: IServiceResponce<IActivity>;
  savingCaseUpdates: IServiceResponce<string>;
  selectedDocument: IDocument | null;
  selectedCase: ICase | null;
  selectedCaseSummary: string | null;
  selectedCasePrivicy: number | null;
  categories: ICategories[] | null;
  casePrivicyLevels: ICasePrivacy[] | null;
  documentPrivacyLevels: IDocumentPrivacy[] | null;
  caseEditsToSave: IDocument | ICaseEditsState | null;
  uploadComplete: boolean;
}

const initialState: IState = {
  cases: {
    data: null,
    pagination: { skip: 0, top: 10, total: 0 },
    isLoading: false,
    hasError: false,
  },
  profile: {
    data: null,
    pagination: { skip: 0, top: 10, total: 0 },
    isLoading: false,
    hasError: false,
  },
  activites: {
    data: null,
    pagination: null,
    isLoading: false,
    hasError: false,
  },
  savingCaseUpdates: {
    data: null,
    pagination: null,
    isLoading: false,
    hasError: false,
  },
  selectedDocument: null,
  selectedCase: null,
  status: null,
  selectedCaseSummary: null,
  selectedCasePrivicy: null,
  categories: null,
  casePrivicyLevels: null,
  caseEditsToSave: null,
  documentPrivacyLevels: null,
  uploadComplete: false,
};

export type CasesPayload = {
  include: string;
  sort: string;
  top: number;
  skip: number;
  filter?: { items: ITableFilter[] };
};

export const fetchAllCases = createAsyncThunk(
  `${NAME}/fetchAllCases`,
  async (args: CasesPayload, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/cases`, args);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchCaseDetailsById = createAsyncThunk(
  `${NAME}/fetchCaseDetailsById`,
  async (
    arg: {
      deliveryEngagementId: string | number;
      top: number;
      skip: number;
      hideLoading?: boolean;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.get(
        `/cases/details/${arg.deliveryEngagementId}?top=${arg.top}&skip=${arg.skip}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchCaseActivitiesById = createAsyncThunk(
  `${NAME}/fetchCaseActivitiesById`,
  async (arg: { deliveryEngagementId: string | number }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/cases/activities/${arg.deliveryEngagementId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteDocumentbyId = createAsyncThunk(
  `${NAME}/deleteDocumentbyId`,
  async (
    arg: { deliveryEngagementId: string | number; documentId: number | string },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.delete(
        `/cases/details/${arg.deliveryEngagementId}/${arg.documentId}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDocumentCategories = createAsyncThunk(
  `${NAME}/getDocumentCategories`,
  async (arg: null, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/documents/categories`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCasePrivicyLevels = createAsyncThunk(
  `${NAME}/getCasePrivicyLevels`,
  async (arg: null, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/cases/privacy-levels`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDocumentPrivicyLevels = createAsyncThunk(
  `${NAME}/getDocumentPrivicyLevels`,
  async (arg: null, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/documents/privacy-types`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IDocumentEditsPayload {
  deliveryEngagementId: string | number;
  payload: IDocument;
}

export const putDocumentDetails = createAsyncThunk(
  `${NAME}/putCaseDetails`,
  async (arg: IDocumentEditsPayload, thunkAPI) => {
    const { deliveryEngagementId, payload } = arg;
    console.log("putDocumentDetails", arg);
    const formatedPayload: ICasePayload = {
      documents: [
        {
          documentId: payload.documentId,
          summary: payload.summary,
          fileNameOriginal: payload.fileNameOriginal,
          categoryId: payload.categoryId,
          documentPrivacyLevels: payload.documentPrivacyLevels,
        },
      ],
    };

    try {
      const response = await axiosInstance.put(
        `/cases/details/${deliveryEngagementId}`,
        formatedPayload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface ICaseEditsPayload {
  deliveryEngagementId: string | number;
  payload: ICaseEditsState;
}

export const putCaseDetails = createAsyncThunk(
  `${NAME}/putCaseDetails`,
  async (arg: ICaseEditsPayload, thunkAPI) => {
    const { deliveryEngagementId, payload } = arg;
    console.log("putDocumentDetails", arg);

    const { summary, privacyLevel } = payload;
    const formatedPayload: ICasePayload = {
      caseDetails: {
        casePrivacyLevelId: privacyLevel,
      },
    };
    if (summary != null) {
      formatedPayload.caseDetails!["summary"] = summary;
    }

    try {
      const response = await axiosInstance.put(
        `/cases/details/${deliveryEngagementId}`,
        formatedPayload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const reducers = {
  setSelectedCase: (state: IState, { payload: data }) => {
    state.selectedCase = data as ICase;
  },
  setSelectedDocument: (state: IState, { payload: data }) => {
    state.selectedDocument = data as IDocument;
  },
  setSelectedCaseActivites: (state: IState, { payload: data }) => {
    //state.activites = data as IActivity[];
  },
  setSelectedCaseStatuses: (state: IState, { payload: data }) => {
    state.status = data as IStatuses;
  },
  setSelectedCasePagination: (state: IState, { payload: data }) => {
    state.profile.pagination = data as IPageination;
  },
  setUploadComplete: (state: IState, { payload: data }) => {
    state.uploadComplete = data as boolean;
  },
  setCaseEditsToSave: (state: IState, { payload: data }) => {
    state.caseEditsToSave = data as IDocument | ICaseEditsState;
  },
  setCasePrivacyLevel: (state: IState, { payload: data }) => {
    state.selectedCasePrivicy = data as number;
  },
  setCaseSummary: (state: IState, { payload: data }) => {
    state.selectedCaseSummary = data as string;
  },
};

export const casesSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCases.pending, (state) => {
      const clone = { ...state.cases };
      clone.isLoading = true;
      clone.data = null;
      clone.hasError = false;
      state.cases = clone;
      state.selectedCase = null;
      state.selectedDocument = null;
      state.status = null;
      state.selectedCaseSummary = null;
      state.selectedCasePrivicy = null;
      state.activites = { ...initialState.activites };
    });
    builder.addCase(fetchAllCases.fulfilled, (state, action) => {
      const clone = { ...state.cases };
      clone.data = action.payload.results as ICase[];
      clone.pagination = action.payload.pagination as IPageination;
      clone.isLoading = false;
      state.cases = clone;
    });
    builder.addCase(fetchAllCases.rejected, (state) => {
      const clone = { ...state.cases };
      clone.isLoading = false;
      clone.hasError = true;
      state.cases = clone;
    });
    builder.addCase(fetchCaseDetailsById.pending, (state, action) => {
      const { meta } = action;
      const clone = { ...state.profile };
      clone.isLoading = meta.arg.hideLoading ? false : true;
      state.profile = clone;
      state.selectedDocument = null;
      state.status = null;
      state.selectedCaseSummary = null;
      state.selectedCasePrivicy = null;
    });
    builder.addCase(fetchCaseDetailsById.fulfilled, (state, action) => {
      const clone = { ...state.profile };
      clone.data = action.payload.results.reverse() as ICaseDetails[];
      clone.pagination = action.payload.pagination as IPageination;
      clone.isLoading = false;
      state.profile = clone;
      state.status = action.payload.statuses as IStatuses;
      state.selectedCaseSummary = action.payload.summary;
      state.selectedCasePrivicy = action.payload.casePrivacyLevelId;
    });
    builder.addCase(fetchCaseDetailsById.rejected, (state) => {
      const clone = { ...state.profile };
      clone.isLoading = false;
      clone.hasError = true;
      state.profile = clone;
    });
    builder.addCase(fetchCaseActivitiesById.pending, (state) => {
      const clone = { ...state.activites };
      clone.isLoading = true;
      clone.hasError = false;
      state.activites = clone;
    });
    builder.addCase(fetchCaseActivitiesById.fulfilled, (state, action) => {
      const clone = { ...state.activites };
      clone.data = action.payload.results as IActivity[];
      clone.isLoading = false;
      state.activites = clone;
    });
    builder.addCase(fetchCaseActivitiesById.rejected, (state) => {
      const clone = { ...state.activites };
      clone.isLoading = false;
      clone.hasError = true;
      state.activites = clone;
    });
    builder.addCase(getDocumentCategories.fulfilled, (state, action) => {
      state.categories = action.payload.data.results as ICategories[];
    });
    builder.addCase(getDocumentPrivicyLevels.fulfilled, (state, action) => {
      state.documentPrivacyLevels = action.payload.data
        .results as IDocumentPrivacy[];
    });
    builder.addCase(getCasePrivicyLevels.fulfilled, (state, action) => {
      state.casePrivicyLevels = action.payload.data.results as ICasePrivacy[];
    });
    builder.addCase(putDocumentDetails.pending, (state) => {
      const clone = { ...state.savingCaseUpdates };
      clone.isLoading = true;
      clone.hasError = false;
      state.savingCaseUpdates = clone;
    });
    builder.addCase(putDocumentDetails.fulfilled, (state) => {
      const clone = { ...state.savingCaseUpdates };
      clone.isLoading = false;
      clone.data = ["complete"];
      clone.hasError = false;
      state.savingCaseUpdates = clone;
    });
    builder.addCase(putDocumentDetails.rejected, (state) => {
      const clone = { ...state.savingCaseUpdates };
      clone.isLoading = false;
      clone.data = null;
      clone.hasError = true;
      state.savingCaseUpdates = clone;
    });
  },
});
/*
documentPrivacyLevels
*/
export const selectCasesState = (state) => state[NAME].cases;
export const selectIsLoading = (state) => state[NAME].cases.isLoading;
export const selectIsError = (state) => state[NAME].cases.hasError;
export const selectCases = (state) => state[NAME].cases.data;
export const selectPagination = (state) => state[NAME].cases.pagination;
/*

*/
export const selectProfileState = (state) => state[NAME].profile;
export const selectSelectedCaseDetails = (state) => state[NAME].profile.data;
export const selectIsProfileLoading = (state) => state[NAME].profile.isLoading;
export const selectProfilePagination = (state) =>
  state[NAME].profile.pagination;
export const selectIsProfileError = (state) => state[NAME].profile.hasError;
/*

*/
export const selectActivitesState = (state) => state[NAME].activites;
export const selectActivitesIsLoading = (state) =>
  state[NAME].activites.isLoading;
export const selectActivitesIsError = (state) => state[NAME].activites.hasError;
export const selectActivites = (state) => state[NAME].activites.data;
export const selectActivitesPagination = (state) =>
  state[NAME].activites.pagination;

/*

*/
export const selectSelectedCase = (state) => state[NAME].selectedCase;
export const selectSelectedDocument = (state) => state[NAME].selectedDocument;
export const selectSelectedCaseStatus = (state) => state[NAME].status;
export const selectDocumentCatagories = (state) => state[NAME].categories;
export const selectCasePrivacyLevels = (state) => state[NAME].casePrivicyLevels;
export const selectDocumentPrivacyLevels = (state) =>
  state[NAME].documentPrivacyLevels;
export const selectUploadComplete = (state) => state[NAME].uploadComplete;
export const selectDeliveryEngagementId = (state) => {
  return state[NAME].selectedCase.DeliveryEngagementId;
};

export const selectSelectedCaseSummary = (state) =>
  state[NAME].selectedCaseSummary;
export const selectSelectedCasePrivicyLevel = (state) =>
  state[NAME].selectedCasePrivicy;
export const selectEditsToSave = (state) => state[NAME].caseEditsToSave;
/*

*/
export const {
  setSelectedCase,
  setSelectedDocument,
  setSelectedCaseActivites,
  setSelectedCasePagination,
  setSelectedCaseStatuses,
  setUploadComplete,
  setCaseEditsToSave,
  setCasePrivacyLevel,
  setCaseSummary,
} = casesSlice.actions;

export default casesSlice.reducer;
