import React from "react";
import { Box, Typography } from "@mui/material";
import TimeLine from "@/components/TimeLine/TimeLine";
import { config } from "./CaseRequirementsStatus.config";
import { IStatuses } from "@/store/store.types";
import { motion } from "framer-motion";
import { ANNIMATION_SPEED } from "../../../constants";

type Props = {
  statuses: IStatuses;
  isRefresh?: boolean;
};

const CaseRequirementsStatus = ({ statuses, isRefresh }: Props) => {
  return (
    <Box>
      <Typography variant="h6">Case Requirements</Typography>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: isRefresh ? 0 : ANNIMATION_SPEED }}
      >
        <TimeLine data={config(statuses)} limit={4} />
      </motion.div>
    </Box>
  );
};

export default CaseRequirementsStatus;
