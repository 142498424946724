import { IFilterMenu } from "@adl/smart-ui-library";

const markets: IFilterMenu[] = [
  { type: "markets", label: "Netherlands", value: 1, param: "Netherlands" },
  { type: "markets", label: "Lebanon", value: 2, param: "Lebanon" },
  { type: "markets", label: "Serbia", value: 3, param: "Serbia" },
  { type: "markets", label: "Thailand", value: 4, param: "Thailand" },
  { type: "markets", label: "Colombia", value: 5, param: "Colombia" },
  { type: "markets", label: "United States", value: 6, param: "United States" },
  { type: "markets", label: "Belgium", value: 7, param: "Belgium" },
  { type: "markets", label: "Argentina", value: 8, param: "Argentina" },
  {
    type: "markets",
    label: "United Kingdom",
    value: 9,
    param: "United Kingdom",
  },
  {
    type: "markets",
    label: "United Arab Emirates",
    value: 10,
    param: "United Arab Emirates",
  },
  { type: "markets", label: "Germany", value: 11, param: "Germany" },
  { type: "markets", label: "Sweden", value: 12, param: "Sweden" },
  { type: "markets", label: "India", value: 13, param: "India" },
  { type: "markets", label: "China", value: 14, param: "China" },
  { type: "markets", label: "Turkey", value: 15, param: "Turkey" },
  { type: "markets", label: "Spain", value: 16, param: "Spain" },
  { type: "markets", label: "Bahrain", value: 17, param: "Bahrain" },
  { type: "markets", label: "Mexico", value: 18, param: "Mexico" },
  { type: "markets", label: "Italy", value: 19, param: "Italy" },
  { type: "markets", label: "Russia", value: 20, param: "Russia" },
  { type: "markets", label: "Norway", value: 21, param: "Norway" },
  { type: "markets", label: "France", value: 22, param: "France" },
  {
    type: "markets",
    label: "Czech Republic",
    value: 23,
    param: "Czech Republic",
  },
  { type: "markets", label: "Saudi Arabia", value: 24, param: "Saudi Arabia" },
  { type: "markets", label: "Korea", value: 25, param: "Korea" },
  { type: "markets", label: "Singapore", value: 26, param: "Singapore" },
  { type: "markets", label: "Japan", value: 27, param: "Japan" },
  { type: "markets", label: "Austria", value: 28, param: "Austria" },
  { type: "markets", label: "Poland", value: 29, param: "Poland" },
  {
    type: "markets",
    label: "United States of America",
    value: 30,
    param: "United States of America",
  },
  { type: "markets", label: "Taiwan", value: 31, param: "Taiwan" },
  { type: "markets", label: "Azerbaijan", value: 32, param: "Azerbaijan" },
  { type: "markets", label: "Luxembourg", value: 33, param: "Luxembourg" },
  { type: "markets", label: "Global", value: 34, param: "Global" },
  { type: "markets", label: "Interco", value: 35, param: "Interco" },
  { type: "markets", label: "Services", value: 36, param: "Services" },
  { type: "markets", label: "Abu Dhabi", value: 37, param: "Abu Dhabi" },
  { type: "markets", label: "Iran", value: 38, param: "Iran" },
  { type: "markets", label: "Switzerland", value: 39, param: "Switzerland" },
  { type: "markets", label: "Dubai", value: 40, param: "Dubai" },
  { type: "markets", label: "Czech", value: 41, param: "Czech" },
  { type: "markets", label: "Hong Kong", value: 42, param: "Hong Kong" },
  { type: "markets", label: "USA", value: 43, param: "USA" },
  { type: "markets", label: "Venezuela", value: 44, param: "Venezuela" },
  { type: "markets", label: "Brazil", value: 45, param: "Brazil" },
  { type: "markets", label: "Kuwait", value: 46, param: "Kuwait" },
  { type: "markets", label: "Malaysia", value: 47, param: "Malaysia" },
  {
    type: "markets",
    label: "Kingdom of Saudi Arabia",
    value: 48,
    param: "Kingdom of Saudi Arabia",
  },
  { type: "markets", label: "Portugal", value: 49, param: "Portugal" },
  { type: "markets", label: "Oman", value: 50, param: "Oman" },
  { type: "markets", label: "Australia", value: 51, param: "Australia" },
  { type: "markets", label: "Guatemala", value: 52, param: "Guatemala" },
  { type: "markets", label: "Indonesia", value: 53, param: "Indonesia" },
  { type: "markets", label: "Presans", value: 54, param: "Presans" },
  { type: "markets", label: "Romania", value: 55, param: "Romania" },
  { type: "markets", label: "Qatar", value: 56, param: "Qatar" },
];

export default markets;
