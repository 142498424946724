import { Breadcrumbs, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import {
  PageHeader,
  SmartSearchLogo,
  PageContent,
} from "@adl/smart-ui-library";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  setSelectedCase,
  selectSelectedCase,
  selectEditsToSave,
  putCaseDetails,
  setCaseEditsToSave,
  setCasePrivacyLevel,
  setCaseSummary,
} from "@/redux/casesSlice";
import CaseProfileWrapper from "@/components/CaseProfileWrapper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useMsal } from "@azure/msal-react";
import NotificationDropdown from "@/components/Notifications/Notifications";
import { setSelectedTasks } from "@/redux/tasksSlice";
import SaveChangesModal from "@/components/SaveChangesModal";
import DiscardChangesModal from "@/components/DiscardChangesModal";
import VaultIcon from "../../components/VaultIcon/VaultIcon";

const SelectedCase = () => {
  const { instance } = useMsal();
  const appDispatch = useAppDispatch();
  const editsToSave = useAppSelector(selectEditsToSave);
  const caseProfile = useAppSelector(selectSelectedCase);
  const [saveModalOpen, setSaveModalOpen] = React.useState<boolean>(false);
  const [continueModalOpen, setContinueModalOpen] = React.useState<any | null>(
    null
  );

  const { P_number, DeliveryEngagementId } = caseProfile;

  const onLogout = () => {
    instance.logout();
  };

  const onTaskBtnClick = () => {
    appDispatch(setSelectedTasks([]));
  };

  const onHomeBtnClick = () => {
    if (editsToSave) {
      setContinueModalOpen(() => () => appDispatch(setSelectedCase(null)));
    } else {
      appDispatch(setSelectedCase(null));
    }
  };

  const onSaveRequest = () => {
    if (editsToSave) {
      setSaveModalOpen(true);
    }
  };

  const onSave = () => {
    appDispatch(
      putCaseDetails({
        deliveryEngagementId: DeliveryEngagementId,
        payload: editsToSave,
      })
    );
    appDispatch(setCasePrivacyLevel(editsToSave.privacyLevel));
    appDispatch(setCaseSummary(editsToSave.summary));
    appDispatch(setCaseEditsToSave(null));
    setSaveModalOpen(false);
  };

  const onContinue = () => {
    continueModalOpen(null);
  };

  return (
    <DefaultTemplate paddingTop={200}>
      <PageHeader
        logo={<VaultIcon width={40} height={40} handleClick={onHomeBtnClick} />}
        navigation={
          <>
            <NotificationDropdown />
            <Button onClick={onTaskBtnClick}>Tasks</Button>
            <Button onClick={onLogout}>Logout</Button>
          </>
        }
        subHeader={
          <>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <IconButton onClick={onHomeBtnClick}>
                <HomeOutlinedIcon />
              </IconButton>
              <Typography color="text.primary">{P_number}</Typography>
            </Breadcrumbs>
            <Button
              variant="contained"
              onClick={onSaveRequest}
              disableElevation
              disabled={Boolean(!editsToSave)}
            >
              Save Changes
            </Button>
          </>
        }
      >
        <Typography variant="h1">Knowledge Vault</Typography>
      </PageHeader>

      <PageContent>
        <CaseProfileWrapper />
      </PageContent>
      <SaveChangesModal
        open={Boolean(saveModalOpen)}
        onClose={() => setSaveModalOpen(false)}
        onSave={onSave}
      />
      <DiscardChangesModal
        open={Boolean(continueModalOpen)}
        onClose={() => setContinueModalOpen(false)}
        onContinue={onContinue}
      />
    </DefaultTemplate>
  );
};

export default SelectedCase;
