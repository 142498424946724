import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "@/hooks";
import { selectCasePrivacyLevels } from "@/redux/casesSlice";
import { ICasePrivacy } from "@/redux/store.types";
import { v4 } from "uuid";

type Props = {
  privacyLevel: number;
  onUpdate: (level: number) => void;
};

const CasePrivacyRestrictions = ({ privacyLevel, onUpdate }: Props) => {
  const privactyLevels = useAppSelector(selectCasePrivacyLevels);

  return (
    <Box>
      <Typography variant="h6">Case Privacy Level</Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(event) => onUpdate(Number(event.target.value))}
          value={privacyLevel}
        >
          {privactyLevels.map((level: ICasePrivacy) => (
            <FormControlLabel
              key={v4()}
              value={level.casePrivacyLevelId}
              control={<Radio />}
              label={level.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
export default CasePrivacyRestrictions;
