import React from "react";
import { ICaseDocSatus } from "../../redux/casesSlice";
import HorzontalTimeLine from "./HorzontalTimeLine";
import { VerticalTimeLine } from "./VerticalTimeLine";

export interface ITimeLineData {
  id: number;
  renderer: React.ReactNode;
  status: ICaseDocSatus;
}

type Props = {
  direction?: "horizontal" | "vertical";
  data: ITimeLineData[];
  limit?: number;
};

export const TimeLine = ({
  direction = "horizontal",
  data,
  limit = 10,
}: Props) => {
  if (direction === "horizontal") {
    return <HorzontalTimeLine data={data} limit={limit} />;
  }
  return <VerticalTimeLine data={data} limit={limit} />;
};

export default TimeLine;
