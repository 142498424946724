import { IFilterMenu } from "@adl/smart-ui-library";

const currencies: IFilterMenu[] = [
  { type: "currency", label: "USD", value: 1, param: "USD" },
  { type: "currency", label: "EUR", value: 2, param: "EUR" },
  { type: "currency", label: "GBP", value: 3, param: "GBP" },
  { type: "currency", label: "JPY", value: 4, param: "JPY" },
  { type: "currency", label: "AUD", value: 5, param: "AUD" },
  { type: "currency", label: "CAD", value: 6, param: "CAD" },
  { type: "currency", label: "CHF", value: 7, param: "CHF" },
  { type: "currency", label: "CNY", value: 8, param: "CNY" },
  { type: "currency", label: "INR", value: 9, param: "INR" },
  { type: "currency", label: "BRL", value: 10, param: "BRL" },
  { type: "currency", label: "RUB", value: 11, param: "RUB" },
  { type: "currency", label: "ZAR", value: 12, param: "ZAR" },
  { type: "currency", label: "HKD", value: 13, param: "HKD" },
  { type: "currency", label: "SGD", value: 14, param: "SGD" },
  { type: "currency", label: "KRW", value: 15, param: "KRW" },
  { type: "currency", label: "MXN", value: 16, param: "MXN" },
  { type: "currency", label: "NZD", value: 17, param: "NZD" },
  { type: "currency", label: "SEK", value: 18, param: "SEK" },
  { type: "currency", label: "NOK", value: 19, param: "NOK" },
  { type: "currency", label: "DKK", value: 20, param: "DKK" },
  { type: "currency", label: "TRY", value: 21, param: "TRY" },
  { type: "currency", label: "PLN", value: 22, param: "PLN" },
  { type: "currency", label: "THB", value: 23, param: "THB" },
  { type: "currency", label: "IDR", value: 24, param: "IDR" },
  { type: "currency", label: "MYR", value: 25, param: "MYR" },
  { type: "currency", label: "PHP", value: 26, param: "PHP" },
  { type: "currency", label: "VND", value: 27, param: "VND" },
  { type: "currency", label: "AED", value: 28, param: "AED" },
  { type: "currency", label: "SAR", value: 29, param: "SAR" },
  { type: "currency", label: "KWD", value: 30, param: "KWD" },
  { type: "currency", label: "EGP", value: 31, param: "EGP" },
  { type: "currency", label: "NGN", value: 32, param: "NGN" },
  { type: "currency", label: "ARS", value: 33, param: "ARS" },
  { type: "currency", label: "CLP", value: 34, param: "CLP" },
  { type: "currency", label: "PEN", value: 35, param: "PEN" },
  { type: "currency", label: "COP", value: 36, param: "COP" },
  { type: "currency", label: "CZK", value: 37, param: "CZK" },
  { type: "currency", label: "HUF", value: 38, param: "HUF" },
  { type: "currency", label: "RON", value: 39, param: "RON" },
  { type: "currency", label: "BGN", value: 40, param: "BGN" },
  { type: "currency", label: "ILS", value: 41, param: "ILS" },
  { type: "currency", label: "UAH", value: 42, param: "UAH" },
  { type: "currency", label: "GHS", value: 43, param: "GHS" },
  { type: "currency", label: "KES", value: 44, param: "KES" },
  { type: "currency", label: "TZS", value: 45, param: "TZS" },
  { type: "currency", label: "UGX", value: 46, param: "UGX" },
  { type: "currency", label: "PKR", value: 47, param: "PKR" },
  { type: "currency", label: "BDT", value: 48, param: "BDT" },
  { type: "currency", label: "LKR", value: 49, param: "LKR" },
  { type: "currency", label: "MMK", value: 50, param: "MMK" },
];

export default currencies;
