import { IFilterMenu } from "@adl/smart-ui-library";

const methodology: IFilterMenu[] = [
  { type: "methodology", label: "Methodology", value: 1, param: "Methodology" },
  {
    type: "methodology",
    label: "Due Diligence",
    value: 2,
    param: "Due Diligence",
  },
  {
    type: "methodology",
    label: "Market Analysis",
    value: 3,
    param: "Market Analysis",
  },
  {
    type: "methodology",
    label: "Information Management",
    value: 4,
    param: "Information Management",
  },
  {
    type: "methodology",
    label: "Growth Acceleration",
    value: 5,
    param: "Growth Acceleration",
  },
  {
    type: "methodology",
    label: "Total Company Performance",
    value: 6,
    param: "Total Company Performance",
  },
  {
    type: "methodology",
    label: "Innovation strategy and management",
    value: 7,
    param: "Innovation strategy and management",
  },
  { type: "methodology", label: "IS Planning", value: 8, param: "IS Planning" },
  {
    type: "methodology",
    label: "IS Organization",
    value: 9,
    param: "IS Organization",
  },
  {
    type: "methodology",
    label: "Strategic Planning",
    value: 10,
    param: "Strategic Planning",
  },
  {
    type: "methodology",
    label: "Strategy Development",
    value: 11,
    param: "Strategy Development",
  },
  { type: "methodology", label: "Valuation", value: 12, param: "Valuation" },
  {
    type: "methodology",
    label: "Safety ＆ Risk Management",
    value: 13,
    param: "Safety ＆ Risk Management",
  },
  {
    type: "methodology",
    label: "Corporate Finance Management",
    value: 14,
    param: "Corporate Finance Management",
  },
  {
    type: "methodology",
    label: "Strategy ＆ Growth",
    value: 15,
    param: "Strategy ＆ Growth",
  },
  {
    type: "methodology",
    label: "Balanced Scorecard",
    value: 16,
    param: "Balanced Scorecard",
  },
  {
    type: "methodology",
    label: "Working Capital Improvement",
    value: 17,
    param: "Working Capital Improvement",
  },
  {
    type: "methodology",
    label: "Sourcing ＆ Procurement",
    value: 18,
    param: "Sourcing ＆ Procurement",
  },
  {
    type: "methodology",
    label: "Quality Management",
    value: 19,
    param: "Quality Management",
  },
  {
    type: "methodology",
    label: "Benchmarking",
    value: 20,
    param: "Benchmarking",
  },
  {
    type: "methodology",
    label: "Innovation diagnostics and benchmarking",
    value: 21,
    param: "Innovation diagnostics and benchmarking",
  },
  {
    type: "methodology",
    label: "Pricing Strategy",
    value: 22,
    param: "Pricing Strategy",
  },
  {
    type: "methodology",
    label: "R ＆ D, innovation and technology process improvement",
    value: 23,
    param: "R ＆ D, innovation and technology process improvement",
  },
  {
    type: "methodology",
    label: "R ＆ D, innovation and technology productivity and efficiency",
    value: 24,
    param: "R ＆ D, innovation and technology productivity and efficiency",
  },
  { type: "methodology", label: "IT Supply", value: 25, param: "IT Supply" },
  {
    type: "methodology",
    label: "Marketing / Customer / Sales Strategy",
    value: 26,
    param: "Marketing / Customer / Sales Strategy",
  },
  {
    type: "methodology",
    label: "Human Resource Development ＆ Leadership",
    value: 27,
    param: "Human Resource Development ＆ Leadership",
  },
  {
    type: "methodology",
    label: "Maintenance Performance Excellence",
    value: 28,
    param: "Maintenance Performance Excellence",
  },
  {
    type: "methodology",
    label: "Manage core innovation investment",
    value: 29,
    param: "Manage core innovation investment",
  },
  {
    type: "methodology",
    label: "Technology ＆ Innovation Management",
    value: 30,
    param: "Technology ＆ Innovation Management",
  },
  {
    type: "methodology",
    label: "Safety Management Systems",
    value: 31,
    param: "Safety Management Systems",
  },
  {
    type: "methodology",
    label: "R ＆ D, innovation and technology organisation design",
    value: 32,
    param: "R ＆ D, innovation and technology organisation design",
  },
  {
    type: "methodology",
    label: "Operations Management",
    value: 33,
    param: "Operations Management",
  },
  {
    type: "methodology",
    label: "Production ＆ Logistics",
    value: 34,
    param: "Production ＆ Logistics",
  },
  {
    type: "methodology",
    label: "Shared Service Center Organization",
    value: 35,
    param: "Shared Service Center Organization",
  },
  {
    type: "methodology",
    label: "Marketig / Customer / Sales Excellence",
    value: 36,
    param: "Marketig / Customer / Sales Excellence",
  },
  {
    type: "methodology",
    label: "Technology due diligence",
    value: 37,
    param: "Technology due diligence",
  },
  { type: "methodology", label: "Legal", value: 38, param: "Legal" },
  {
    type: "methodology",
    label: "Make or Buy",
    value: 39,
    param: "Make or Buy",
  },
  {
    type: "methodology",
    label: "Product portfolio strategy and management",
    value: 40,
    param: "Product portfolio strategy and management",
  },
  {
    type: "methodology",
    label: "Strategy Evaluation",
    value: 41,
    param: "Strategy Evaluation",
  },
  {
    type: "methodology",
    label: "Transformation",
    value: 42,
    param: "Transformation",
  },
  {
    type: "methodology",
    label: "Project Management",
    value: 43,
    param: "Project Management",
  },
  {
    type: "methodology",
    label: "Business Planning",
    value: 44,
    param: "Business Planning",
  },
  {
    type: "methodology",
    label: "Controlling",
    value: 45,
    param: "Controlling",
  },
  {
    type: "methodology",
    label: "IS Controlling",
    value: 46,
    param: "IS Controlling",
  },
  {
    type: "methodology",
    label: "Assets ＆ Capital Projects",
    value: 47,
    param: "Assets ＆ Capital Projects",
  },
  {
    type: "methodology",
    label: "Integrated Supply Chain",
    value: 48,
    param: "Integrated Supply Chain",
  },
  {
    type: "methodology",
    label: "Asset Management Optimization",
    value: 49,
    param: "Asset Management Optimization",
  },
  {
    type: "methodology",
    label: "Asset Risk Management",
    value: 50,
    param: "Asset Risk Management",
  },
  {
    type: "methodology",
    label: "Asset Strategy",
    value: 51,
    param: "Asset Strategy",
  },
  {
    type: "methodology",
    label: "Capital Projects Optimization",
    value: 52,
    param: "Capital Projects Optimization",
  },
  {
    type: "methodology",
    label: "Future of Operations / Industry 4.0",
    value: 53,
    param: "Future of Operations / Industry 4.0",
  },
  {
    type: "methodology",
    label: "Knowledge Management",
    value: 54,
    param: "Knowledge Management",
  },
  {
    type: "methodology",
    label: "IP strategy and management",
    value: 55,
    param: "IP strategy and management",
  },
  {
    type: "methodology",
    label: "Logistics Performance Improvement",
    value: 56,
    param: "Logistics Performance Improvement",
  },
  {
    type: "methodology",
    label: "Business Process Redesign",
    value: 57,
    param: "Business Process Redesign",
  },
  {
    type: "methodology",
    label: "Production Network Redesign",
    value: 58,
    param: "Production Network Redesign",
  },
  {
    type: "methodology",
    label: "Production Performance Improvement",
    value: 59,
    param: "Production Performance Improvement",
  },
  {
    type: "methodology",
    label: "Total Cost ＆ Quality Improvement",
    value: 60,
    param: "Total Cost ＆ Quality Improvement",
  },
  {
    type: "methodology",
    label: "Value Management",
    value: 61,
    param: "Value Management",
  },
  {
    type: "methodology",
    label: "Task ＆ Structure Analysis",
    value: 62,
    param: "Task ＆ Structure Analysis",
  },
  {
    type: "methodology",
    label: "Condition Driven Strategy",
    value: 63,
    param: "Condition Driven Strategy",
  },
  {
    type: "methodology",
    label: "Growth Strategy Development",
    value: 64,
    param: "Growth Strategy Development",
  },
  {
    type: "methodology",
    label: "Strategic Business Unit Development",
    value: 65,
    param: "Strategic Business Unit Development",
  },
  {
    type: "methodology",
    label: "Sourcing Operating Model Redesign",
    value: 66,
    param: "Sourcing Operating Model Redesign",
  },
  {
    type: "methodology",
    label: "Strategic / Value Sourcing",
    value: 67,
    param: "Strategic / Value Sourcing",
  },
  {
    type: "methodology",
    label: "Supplier Management Improvement",
    value: 68,
    param: "Supplier Management Improvement",
  },
  {
    type: "methodology",
    label: "Organization Development",
    value: 69,
    param: "Organization Development",
  },
  {
    type: "methodology",
    label: "Next Gen IT",
    value: 70,
    param: "Next Gen IT",
  },
  {
    type: "methodology",
    label: "IS Governance",
    value: 71,
    param: "IS Governance",
  },
  {
    type: "methodology",
    label: "Technology strategy and management",
    value: 72,
    param: "Technology strategy and management",
  },
  {
    type: "methodology",
    label: "Marketing / Customer / Sales Management",
    value: 73,
    param: "Marketing / Customer / Sales Management",
  },
  {
    type: "methodology",
    label: "Mergers / Alliances / Partnerships",
    value: 74,
    param: "Mergers / Alliances / Partnerships",
  },
  {
    type: "methodology",
    label: "Sustainability",
    value: 75,
    param: "Sustainability",
  },
  {
    type: "methodology",
    label: "Organizational Design",
    value: 76,
    param: "Organizational Design",
  },
  {
    type: "methodology",
    label: "Process Safety",
    value: 77,
    param: "Process Safety",
  },
  {
    type: "methodology",
    label: "Post Merger Integration",
    value: 78,
    param: "Post Merger Integration",
  },
  {
    type: "methodology",
    label: "Strategic Alliance Management",
    value: 79,
    param: "Strategic Alliance Management",
  },
  {
    type: "methodology",
    label: "Intelligence and forecasting",
    value: 80,
    param: "Intelligence and forecasting",
  },
  {
    type: "methodology",
    label: "High Performance Business Model (SPRO)",
    value: 81,
    param: "High Performance Business Model (SPRO)",
  },
  {
    type: "methodology",
    label: "Change Management",
    value: 82,
    param: "Change Management",
  },
  {
    type: "methodology",
    label: "Restructuring",
    value: 83,
    param: "Restructuring",
  },
  {
    type: "methodology",
    label: "Venture Management",
    value: 84,
    param: "Venture Management",
  },
  {
    type: "methodology",
    label: "IS Outsourcing",
    value: 85,
    param: "IS Outsourcing",
  },
  {
    type: "methodology",
    label: "IS Sourcing",
    value: 86,
    param: "IS Sourcing",
  },
  {
    type: "methodology",
    label: "IT Cost Cutting",
    value: 87,
    param: "IT Cost Cutting",
  },
  {
    type: "methodology",
    label: "Portfolio Management",
    value: 88,
    param: "Portfolio Management",
  },
  {
    type: "methodology",
    label: "Multi Project Management",
    value: 89,
    param: "Multi Project Management",
  },
  {
    type: "methodology",
    label: "Innovation capability development and training",
    value: 90,
    param: "Innovation capability development and training",
  },
  {
    type: "methodology",
    label: "Common Methodologies ＆ Tools",
    value: 91,
    param: "Common Methodologies ＆ Tools",
  },
  {
    type: "methodology",
    label: "Financial Analysis",
    value: 92,
    param: "Financial Analysis",
  },
  { type: "methodology", label: "Audit", value: 93, param: "Audit" },
  { type: "methodology", label: "IS Audit", value: 94, param: "IS Audit" },
  {
    type: "methodology",
    label: "Capability Building",
    value: 95,
    param: "Capability Building",
  },
  {
    type: "methodology",
    label: "Product Strategy",
    value: 96,
    param: "Product Strategy",
  },
  {
    type: "methodology",
    label: "Sustainability ＆ Social Responsibility",
    value: 97,
    param: "Sustainability ＆ Social Responsibility",
  },
  {
    type: "methodology",
    label: "Environmental ＆ Social Assessment",
    value: 98,
    param: "Environmental ＆ Social Assessment",
  },
  {
    type: "methodology",
    label: "Merger ＆ Acquisition Assistance",
    value: 99,
    param: "Merger ＆ Acquisition Assistance",
  },
  {
    type: "methodology",
    label: "Investment Appraisal",
    value: 100,
    param: "Investment Appraisal",
  },
  {
    type: "methodology",
    label: "Ambition Driven Strategy",
    value: 101,
    param: "Ambition Driven Strategy",
  },
  {
    type: "methodology",
    label: "Supply Chain Strategy",
    value: 102,
    param: "Supply Chain Strategy",
  },
  {
    type: "methodology",
    label: "Digital Operations Transformation",
    value: 103,
    param: "Digital Operations Transformation",
  },
  {
    type: "methodology",
    label: "Venturing and incubation management",
    value: 104,
    param: "Venturing and incubation management",
  },
  {
    type: "methodology",
    label: "Co-Management",
    value: 105,
    param: "Co-Management",
  },
  {
    type: "methodology",
    label: "IT Implementation",
    value: 106,
    param: "IT Implementation",
  },
  {
    type: "methodology",
    label: "Corporate Strategy Development",
    value: 107,
    param: "Corporate Strategy Development",
  },
  {
    type: "methodology",
    label: "Core Competence Analysis",
    value: 108,
    param: "Core Competence Analysis",
  },
  { type: "methodology", label: "Visioning", value: 109, param: "Visioning" },
  {
    type: "methodology",
    label: "Integrated Supply Chain Planning",
    value: 110,
    param: "Integrated Supply Chain Planning",
  },
  {
    type: "methodology",
    label: "Growth ＆ Diversification",
    value: 111,
    param: "Growth ＆ Diversification",
  },
  {
    type: "methodology",
    label: "IT Security",
    value: 112,
    param: "IT Security",
  },
  {
    type: "methodology",
    label: "IS Contract",
    value: 113,
    param: "IS Contract",
  },
  { type: "methodology", label: "Culture", value: 114, param: "Culture" },
  {
    type: "methodology",
    label: "Scenario Development",
    value: 115,
    param: "Scenario Development",
  },
  {
    type: "methodology",
    label: "Ideal Company Profiling",
    value: 116,
    param: "Ideal Company Profiling",
  },
  {
    type: "methodology",
    label: "Option Creation ＆ Structuring",
    value: 117,
    param: "Option Creation ＆ Structuring",
  },
  {
    type: "methodology",
    label: "Organizational Effectiveness ＆ Learning",
    value: 118,
    param: "Organizational Effectiveness ＆ Learning",
  },
  {
    type: "methodology",
    label: "Cost Reduction",
    value: 119,
    param: "Cost Reduction",
  },
  {
    type: "methodology",
    label: "Full Performance",
    value: 120,
    param: "Full Performance",
  },
  {
    type: "methodology",
    label: "Top Line Growth",
    value: 121,
    param: "Top Line Growth",
  },
  {
    type: "methodology",
    label: "Environmental Technology",
    value: 122,
    param: "Environmental Technology",
  },
  {
    type: "methodology",
    label: "Building Innovation Capability",
    value: 123,
    param: "Building Innovation Capability",
  },
  { type: "methodology", label: "Partnering", value: 124, param: "Partnering" },
  {
    type: "methodology",
    label: "Branding Strategy",
    value: 125,
    param: "Branding Strategy",
  },
  {
    type: "methodology",
    label: "Hazard / Risk Management ＆ Assessment",
    value: 126,
    param: "Hazard / Risk Management ＆ Assessment",
  },
  {
    type: "methodology",
    label: "End-to-End Program Support",
    value: 127,
    param: "End-to-End Program Support",
  },
  {
    type: "methodology",
    label: "Finance / Accounting Risk Management",
    value: 128,
    param: "Finance / Accounting Risk Management",
  },
  {
    type: "methodology",
    label: "Innovation culture change and transformation",
    value: 129,
    param: "Innovation culture change and transformation",
  },
  {
    type: "methodology",
    label: "Telematic Service",
    value: 130,
    param: "Telematic Service",
  },
  {
    type: "methodology",
    label: "Corporate Center / Headquarters Organization",
    value: 131,
    param: "Corporate Center / Headquarters Organization",
  },
  {
    type: "methodology",
    label: "Financial Restructuring",
    value: 132,
    param: "Financial Restructuring",
  },
  {
    type: "methodology",
    label: "Strategy ＆ Business / Operating Model Design",
    value: 133,
    param: "Strategy ＆ Business / Operating Model Design",
  },
  {
    type: "methodology",
    label: "Application Architecture",
    value: 134,
    param: "Application Architecture",
  },
  {
    type: "methodology",
    label: "Information Architecture",
    value: 135,
    param: "Information Architecture",
  },
  {
    type: "methodology",
    label: "IS Requirement Analysis",
    value: 136,
    param: "IS Requirement Analysis",
  },
  {
    type: "methodology",
    label: "IS Visioning",
    value: 137,
    param: "IS Visioning",
  },
  {
    type: "methodology",
    label: "IT-Business Impact",
    value: 138,
    param: "IT-Business Impact",
  },
  { type: "methodology", label: "Migration", value: 139, param: "Migration" },
  {
    type: "methodology",
    label: "Technology Architecture",
    value: 140,
    param: "Technology Architecture",
  },
  { type: "methodology", label: "Governance", value: 141, param: "Governance" },
  {
    type: "methodology",
    label: "Diversification Strategy",
    value: 142,
    param: "Diversification Strategy",
  },
  {
    type: "methodology",
    label: "Digital Shift",
    value: 143,
    param: "Digital Shift",
  },
  {
    type: "methodology",
    label: "Divestment Support",
    value: 144,
    param: "Divestment Support",
  },
  {
    type: "methodology",
    label: "Privatization",
    value: 145,
    param: "Privatization",
  },
  {
    type: "methodology",
    label: "Mobile Commerce",
    value: 146,
    param: "Mobile Commerce",
  },
  {
    type: "methodology",
    label: "Logistics Network Redesign",
    value: 147,
    param: "Logistics Network Redesign",
  },
  {
    type: "methodology",
    label: "Implementation",
    value: 148,
    param: "Implementation",
  },
  {
    type: "methodology",
    label: "Agile ＆ Lean Operations",
    value: 149,
    param: "Agile ＆ Lean Operations",
  },
  {
    type: "methodology",
    label: "Public Entities Specific Services",
    value: 150,
    param: "Public Entities Specific Services",
  },
  {
    type: "methodology",
    label: "Organizational Audit",
    value: 151,
    param: "Organizational Audit",
  },
  {
    type: "methodology",
    label: "Liberalization",
    value: 152,
    param: "Liberalization",
  },
  {
    type: "methodology",
    label: "Broad Risk Management",
    value: 153,
    param: "Broad Risk Management",
  },
  {
    type: "methodology",
    label: "End-to-End Process Redesign",
    value: 154,
    param: "End-to-End Process Redesign",
  },
  {
    type: "methodology",
    label: "Operational Excellence / BPM / CIP",
    value: 155,
    param: "Operational Excellence / BPM / CIP",
  },
  {
    type: "methodology",
    label: "Total Operating Model Transformation",
    value: 156,
    param: "Total Operating Model Transformation",
  },
  {
    type: "methodology",
    label: "Service ＆ Corporate Center Operations",
    value: 157,
    param: "Service ＆ Corporate Center Operations",
  },
  {
    type: "methodology",
    label: "Corporate Center Redesign",
    value: 158,
    param: "Corporate Center Redesign",
  },
  {
    type: "methodology",
    label: "Corporate Project Portfolio Optimization",
    value: 159,
    param: "Corporate Project Portfolio Optimization",
  },
  {
    type: "methodology",
    label: "Service Center Design",
    value: 160,
    param: "Service Center Design",
  },
  {
    type: "methodology",
    label: "Service Center Performance Improvement",
    value: 161,
    param: "Service Center Performance Improvement",
  },
  {
    type: "methodology",
    label: "Open innovation and ecosystem development",
    value: 162,
    param: "Open innovation and ecosystem development",
  },
  {
    type: "methodology",
    label: "IT Value Management",
    value: 163,
    param: "IT Value Management",
  },
  {
    type: "methodology",
    label: "Innovation outsourcing and digital solutions",
    value: 164,
    param: "Innovation outsourcing and digital solutions",
  },
  {
    type: "methodology",
    label: "IS Organization Structure",
    value: 165,
    param: "IS Organization Structure",
  },
  {
    type: "methodology",
    label: "Safety / Risk Training",
    value: 166,
    param: "Safety / Risk Training",
  },
  {
    type: "methodology",
    label: "Feasibility Study",
    value: 167,
    param: "Feasibility Study",
  },
  {
    type: "methodology",
    label: "Governmental Policy Planning",
    value: 168,
    param: "Governmental Policy Planning",
  },
  {
    type: "methodology",
    label: "Policy ＆ Program Evaluation",
    value: 169,
    param: "Policy ＆ Program Evaluation",
  },
  {
    type: "methodology",
    label: "Market Access Support",
    value: 170,
    param: "Market Access Support",
  },
  {
    type: "methodology",
    label: "Roll-out ＆ Deployment",
    value: 171,
    param: "Roll-out ＆ Deployment",
  },
  {
    type: "methodology",
    label: "Economic Reform ＆ Development",
    value: 172,
    param: "Economic Reform ＆ Development",
  },
  { type: "methodology", label: "Arbitrage", value: 173, param: "Arbitrage" },
  {
    type: "methodology",
    label: "Dispute Resolution",
    value: 174,
    param: "Dispute Resolution",
  },
  {
    type: "methodology",
    label: "International Expansion Strategy",
    value: 175,
    param: "International Expansion Strategy",
  },
  {
    type: "methodology",
    label: "Operational Unit / SBU Organization",
    value: 176,
    param: "Operational Unit / SBU Organization",
  },
  { type: "methodology", label: "IS Demand", value: 177, param: "IS Demand" },
  {
    type: "methodology",
    label: "IS Processes",
    value: 178,
    param: "IS Processes",
  },
  { type: "methodology", label: "Accounting", value: 179, param: "Accounting" },
  {
    type: "methodology",
    label: "High Impact Purchasing",
    value: 180,
    param: "High Impact Purchasing",
  },
  {
    type: "methodology",
    label: "Sourcing Capability Building",
    value: 181,
    param: "Sourcing Capability Building",
  },
  {
    type: "methodology",
    label: "Deliver end-to-end breakthrough innovation",
    value: 182,
    param: "Deliver end-to-end breakthrough innovation",
  },
  {
    type: "methodology",
    label: "Initial Public Offering Assistance",
    value: 183,
    param: "Initial Public Offering Assistance",
  },
  {
    type: "methodology",
    label: "Financial Due Diligence",
    value: 184,
    param: "Financial Due Diligence",
  },
  {
    type: "methodology",
    label: "Safety / Risk Audit",
    value: 185,
    param: "Safety / Risk Audit",
  },
  { type: "methodology", label: "e-Commerce", value: 186, param: "e-Commerce" },
  {
    type: "methodology",
    label: "Strategic Reporting",
    value: 187,
    param: "Strategic Reporting",
  },
  {
    type: "methodology",
    label: "Joint Venture Assistance",
    value: 188,
    param: "Joint Venture Assistance",
  },
  {
    type: "methodology",
    label: "Mobile Payment",
    value: 189,
    param: "Mobile Payment",
  },
  {
    type: "methodology",
    label: "Building Growth Capabilities",
    value: 190,
    param: "Building Growth Capabilities",
  },
  { type: "methodology", label: "B2E", value: 191, param: "B2E" },
  {
    type: "methodology",
    label: "Architecture",
    value: 192,
    param: "Architecture",
  },
  {
    type: "methodology",
    label: "Breakthrough prototype dev - Breakthrough Factory",
    value: 193,
    param: "Breakthrough prototype dev - Breakthrough Factory",
  },
  {
    type: "methodology",
    label: "Digital Problem Solving",
    value: 194,
    param: "Digital Problem Solving",
  },
  {
    type: "methodology",
    label: "Front end Design",
    value: 195,
    param: "Front end Design",
  },
  {
    type: "methodology",
    label: "Data Architecture",
    value: 196,
    param: "Data Architecture",
  },
  {
    type: "methodology",
    label: "Safety Critical Systems",
    value: 197,
    param: "Safety Critical Systems",
  },
  {
    type: "methodology",
    label: "Safety / Risk Review",
    value: 198,
    param: "Safety / Risk Review",
  },
  {
    type: "methodology",
    label: "Technical Business Analysis",
    value: 199,
    param: "Technical Business Analysis",
  },
  {
    type: "methodology",
    label: "Analysis, Project and Program Management",
    value: 200,
    param: "Analysis, Project and Program Management",
  },
  {
    type: "methodology",
    label: "Regulated ＆ Quality Operations Management",
    value: 201,
    param: "Regulated ＆ Quality Operations Management",
  },
  { type: "methodology", label: "IS Merger", value: 202, param: "IS Merger" },
  {
    type: "methodology",
    label: "Digital Transformation",
    value: 203,
    param: "Digital Transformation",
  },
  {
    type: "methodology",
    label: "Engineering",
    value: 204,
    param: "Engineering",
  },
  {
    type: "methodology",
    label: "IT Service Management",
    value: 205,
    param: "IT Service Management",
  },
  {
    type: "methodology",
    label: "Breakthrough Incubator",
    value: 206,
    param: "Breakthrough Incubator",
  },
  {
    type: "methodology",
    label: "Legislative Acts Building Process",
    value: 207,
    param: "Legislative Acts Building Process",
  },
  {
    type: "methodology",
    label: "Close File Review",
    value: 208,
    param: "Close File Review",
  },
  {
    type: "methodology",
    label: "White Space",
    value: 209,
    param: "White Space",
  },
  { type: "methodology", label: "AI and ML", value: 210, param: "AI and ML" },
  {
    type: "methodology",
    label: "New growth opportunities - Growth Accelerator",
    value: 211,
    param: "New growth opportunities - Growth Accelerator",
  },
  {
    type: "methodology",
    label: "Strategy and Business Advisory",
    value: 212,
    param: "Strategy and Business Advisory",
  },
  {
    type: "methodology",
    label: "Project and Program Delivery",
    value: 213,
    param: "Project and Program Delivery",
  },
  {
    type: "methodology",
    label: "Digital Brand",
    value: 214,
    param: "Digital Brand",
  },
  {
    type: "methodology",
    label: "Administrative Reform",
    value: 215,
    param: "Administrative Reform",
  },
  {
    type: "methodology",
    label: "Supply Risk Management",
    value: 216,
    param: "Supply Risk Management",
  },
  {
    type: "methodology",
    label: "Safety Assurance",
    value: 217,
    param: "Safety Assurance",
  },
  {
    type: "methodology",
    label: "Customer Experience / Voice of Customer",
    value: 218,
    param: "Customer Experience / Voice of Customer",
  },
  {
    type: "methodology",
    label: "Digital Design",
    value: 219,
    param: "Digital Design",
  },
  {
    type: "methodology",
    label: "IT Operating Model",
    value: 220,
    param: "IT Operating Model",
  },
  {
    type: "methodology",
    label: "Technical Architecture",
    value: 221,
    param: "Technical Architecture",
  },
  {
    type: "methodology",
    label: "Solution Architecture",
    value: 222,
    param: "Solution Architecture",
  },
  {
    type: "methodology",
    label: "Technology Governance",
    value: 223,
    param: "Technology Governance",
  },
  {
    type: "methodology",
    label: "Order-to-Cash Process Redesign",
    value: 224,
    param: "Order-to-Cash Process Redesign",
  },
  {
    type: "methodology",
    label: "SG ＆ A Cost Optimization",
    value: 225,
    param: "SG ＆ A Cost Optimization",
  },
  {
    type: "methodology",
    label: "Agile Ways of Working",
    value: 226,
    param: "Agile Ways of Working",
  },
  { type: "methodology", label: "DevOps", value: 227, param: "DevOps" },
  {
    type: "methodology",
    label: "Site Reliability Engineer",
    value: 228,
    param: "Site Reliability Engineer",
  },
  {
    type: "methodology",
    label: "Data Science",
    value: 229,
    param: "Data Science",
  },
  {
    type: "methodology",
    label: "Safety Culture Assessment ＆ Review",
    value: 230,
    param: "Safety Culture Assessment ＆ Review",
  },
  {
    type: "methodology",
    label: "CIO Advisory",
    value: 231,
    param: "CIO Advisory",
  },
  {
    type: "methodology",
    label: "Rapid Prototyping",
    value: 232,
    param: "Rapid Prototyping",
  },
  {
    type: "methodology",
    label: "Technical Project Management",
    value: 233,
    param: "Technical Project Management",
  },
  {
    type: "methodology",
    label: "Technology Cost Optimization",
    value: 234,
    param: "Technology Cost Optimization",
  },
  {
    type: "methodology",
    label: "Common Methodologies & Tools",
    value: 235,
    param: "Common Methodologies & Tools",
  },
  {
    type: "methodology",
    label: "Safety & Risk Management",
    value: 236,
    param: "Safety & Risk Management",
  },
  {
    type: "methodology",
    label: "Strategy & Growth",
    value: 237,
    param: "Strategy & Growth",
  },
  {
    type: "methodology",
    label: "Technology & Innovation Management",
    value: 238,
    param: "Technology & Innovation Management",
  },
  {
    type: "methodology",
    label: "Regulated & Quality Operations Management",
    value: 239,
    param: "Regulated & Quality Operations Management",
  },
  {
    type: "methodology",
    label: "Task & Structure Analysis",
    value: 240,
    param: "Task & Structure Analysis",
  },
  {
    type: "methodology",
    label: "Merger & Acquisition Assistance",
    value: 241,
    param: "Merger & Acquisition Assistance",
  },
  {
    type: "methodology",
    label: "Assets & Capital Projects",
    value: 242,
    param: "Assets & Capital Projects",
  },
  {
    type: "methodology",
    label: "Production & Logistics",
    value: 243,
    param: "Production & Logistics",
  },
  {
    type: "methodology",
    label: "Service & Corporate Center Operations",
    value: 244,
    param: "Service & Corporate Center Operations",
  },
  {
    type: "methodology",
    label: "Sourcing & Procurement",
    value: 245,
    param: "Sourcing & Procurement",
  },
  {
    type: "methodology",
    label: "Human Resource Development & Leadership",
    value: 246,
    param: "Human Resource Development & Leadership",
  },
  {
    type: "methodology",
    label: "Organizational Effectiveness & Learning",
    value: 247,
    param: "Organizational Effectiveness & Learning",
  },
  {
    type: "methodology",
    label: "Economic Reform & Development",
    value: 248,
    param: "Economic Reform & Development",
  },
  {
    type: "methodology",
    label: "Policy & Program Evaluation",
    value: 249,
    param: "Policy & Program Evaluation",
  },
  {
    type: "methodology",
    label: "Hazard / Risk Management & Assessment",
    value: 250,
    param: "Hazard / Risk Management & Assessment",
  },
  {
    type: "methodology",
    label: "Safety Culture Assessment & Review",
    value: 251,
    param: "Safety Culture Assessment & Review",
  },
  {
    type: "methodology",
    label: "Growth & Diversification",
    value: 252,
    param: "Growth & Diversification",
  },
  {
    type: "methodology",
    label: "Environmental & Social Assessment",
    value: 253,
    param: "Environmental & Social Assessment",
  },
  {
    type: "methodology",
    label: "Sustainability & Social Responsibility",
    value: 254,
    param: "Sustainability & Social Responsibility",
  },
  {
    type: "methodology",
    label: "Roll-out & Deployment",
    value: 255,
    param: "Roll-out & Deployment",
  },
  {
    type: "methodology",
    label: "Strategy & Business / Operating Model Design",
    value: 256,
    param: "Strategy & Business / Operating Model Design",
  },
  {
    type: "methodology",
    label: "SG & A Cost Optimization",
    value: 257,
    param: "SG & A Cost Optimization",
  },
  {
    type: "methodology",
    label: "Agile & Lean Operations",
    value: 258,
    param: "Agile & Lean Operations",
  },
  {
    type: "methodology",
    label: "Total Cost & Quality Improvement",
    value: 259,
    param: "Total Cost & Quality Improvement",
  },
  {
    type: "methodology",
    label: "Option Creation & Structuring",
    value: 260,
    param: "Option Creation & Structuring",
  },
  {
    type: "methodology",
    label: "Data Engineering",
    value: 261,
    param: "Data Engineering",
  },
  {
    type: "methodology",
    label: "Scrum Master",
    value: 262,
    param: "Scrum Master",
  },
  {
    type: "methodology",
    label: "User Experience",
    value: 263,
    param: "User Experience",
  },
  {
    type: "methodology",
    label: "User Interface",
    value: 264,
    param: "User Interface",
  },
  { type: "methodology", label: "Front End", value: 265, param: "Front End" },
  { type: "methodology", label: "Back End", value: 266, param: "Back End" },
  { type: "methodology", label: "Full Stack", value: 267, param: "Full Stack" },
  {
    type: "methodology",
    label: "Dev in Test",
    value: 268,
    param: "Dev in Test",
  },
  {
    type: "methodology",
    label: "Automated Test",
    value: 269,
    param: "Automated Test",
  },
  {
    type: "methodology",
    label: "Manual Test",
    value: 270,
    param: "Manual Test",
  },
  { type: "methodology", label: "Drupal 8", value: 271, param: "Drupal 8" },
  {
    type: "methodology",
    label: "R & D, innovation and technology organisation design",
    value: 272,
    param: "R & D, innovation and technology organisation design",
  },
  {
    type: "methodology",
    label: "R & D, innovation and technology process improvement",
    value: 273,
    param: "R & D, innovation and technology process improvement",
  },
  {
    type: "methodology",
    label: "R & D, innovation and technology productivity and efficiency",
    value: 274,
    param: "R & D, innovation and technology productivity and efficiency",
  },
  {
    type: "methodology",
    label: "IT Finance and Procurement",
    value: 275,
    param: "IT Finance and Procurement",
  },
  {
    type: "methodology",
    label: "Technology Cost Optimisation",
    value: 276,
    param: "Technology Cost Optimisation",
  },
  {
    type: "methodology",
    label: "Agile Ways of Working",
    value: 277,
    param: "Agile Ways of Working",
  },
  {
    type: "methodology",
    label: "Computer Vision",
    value: 278,
    param: "Computer Vision",
  },
  {
    type: "methodology",
    label: "Deep Learning",
    value: 279,
    param: "Deep Learning",
  },
  {
    type: "methodology",
    label: "Graph Models",
    value: 280,
    param: "Graph Models",
  },
  {
    type: "methodology",
    label: "Natural Language Processing",
    value: 281,
    param: "Natural Language Processing",
  },
  {
    type: "methodology",
    label: "Neural Networks",
    value: 282,
    param: "Neural Networks",
  },
  { type: "methodology", label: "Innovation", value: 283, param: "Innovation" },
  {
    type: "methodology",
    label: "Innovating for Sustainability",
    value: 284,
    param: "Innovating for Sustainability",
  },
  { type: "methodology", label: "Growth", value: 285, param: "Growth" },
  {
    type: "methodology",
    label: "Unleashing Innovation ＆ Technology Potential",
    value: 286,
    param: "Unleashing Innovation ＆ Technology Potential",
  },
  {
    type: "methodology",
    label: "Empowering Growth Maximizing Shareholders Value",
    value: 287,
    param: "Empowering Growth Maximizing Shareholders Value",
  },
  {
    type: "methodology",
    label: "Expanding Future Innovation Value",
    value: 288,
    param: "Expanding Future Innovation Value",
  },
  {
    type: "methodology",
    label: "Performance",
    value: 289,
    param: "Performance",
  },
  {
    type: "methodology",
    label: "Capitalizing on Disruption",
    value: 290,
    param: "Capitalizing on Disruption",
  },
  {
    type: "methodology",
    label: "Boosting Company Performance",
    value: 291,
    param: "Boosting Company Performance",
  },
  {
    type: "methodology",
    label: "Ensuring Business Resilience",
    value: 292,
    param: "Ensuring Business Resilience",
  },
  {
    type: "methodology",
    label: "Accelerating Transformation",
    value: 293,
    param: "Accelerating Transformation",
  },
  {
    type: "methodology",
    label: "Building a Future-Proof Organization",
    value: 294,
    param: "Building a Future-Proof Organization",
  },
  {
    type: "methodology",
    label: "Unlocking the Value of Sustainability",
    value: 295,
    param: "Unlocking the Value of Sustainability",
  },
  {
    type: "methodology",
    label: "Navigating Equity Investment",
    value: 296,
    param: "Navigating Equity Investment",
  },
  {
    type: "methodology",
    label: "Building the future of Operations",
    value: 297,
    param: "Building the future of Operations",
  },
  {
    type: "methodology",
    label: "Advancing Innovation through AI data and next practice IT",
    value: 298,
    param: "Advancing Innovation through AI data and next practice IT",
  },
  {
    type: "methodology",
    label: "Business Model Innovation",
    value: 299,
    param: "Business Model Innovation",
  },
  {
    type: "methodology",
    label: "Ecosystem Innovation",
    value: 300,
    param: "Ecosystem Innovation",
  },
  {
    type: "methodology",
    label: "Empowering People ＆ Culture",
    value: 301,
    param: "Empowering People ＆ Culture",
  },
];

export default methodology;
