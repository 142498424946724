import { Box } from "@mui/material";
import React from "react";
import packageJson from "../../../package.json";

const d = new Date();
const year = d.getFullYear();

const PageFooter = () => {
  return (
    <Box sx={{ padding: "20px" }}>
      Knowledge Vault v{packageJson.version} &copy; {year} All rights reserved
    </Box>
  );
};

export default PageFooter;
